import {parallel} from 'async';
import {Wave} from 'better-react-spinkit';
import clone from 'lodash/clone';
import isArray from 'lodash/isArray';
import React from 'react';
import Dropdown from 'react-dropdown';
import {withRouter} from 'react-router-dom';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import validate from 'validate.js';
import Alert from '../../lib/Alert';
import Constants from '../../lib/Constants';
import Dealership from '../../models/Dealership';
import User from '../../models/User';

class DealershipForm extends React.Component {
    static INDEX_ROUTE = '/dealerships';

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            model: {},
            businessDelegates: [],
            dealershipManagers: [],
            formValues: {
                name: '',
                businessDelegateId: '',
                dealershipManagerId: '',
                enabled: ''
            },
            processing: false
        };
    }

    componentDidMount() {
        let newState = {
            loading: false,
            model: {},
            businessDelegates: [],
            dealershipManagers: [],
            formValues: {}
        };
        let tasks = {
            businessDelegates: callback => {
                const params = `filter[where][role]=${Constants.Role.BUSINESS_DELEGATE}`;

                User.find(params).then(result => {
                    if (result.status) {
                        newState.businessDelegates = result.data;
                    }

                    callback(null, result.status);
                }).catch(e => callback(e, false));
            },
            dealershipManagers: callback => {
                const params = `filter[where][role]=${Constants.Role.DEALERSHIP_MANAGER}&filter[where][enabled]=true`;

                User.find(params).then(result => {
                    if (result.status) {
                        newState.dealershipManagers = result.data;
                    }

                    callback(null, result.status);
                }).catch(e => callback(e, false));
            },
        };

        if (this.isEditMode()) {
            tasks.model = callback => {
                const params = `filter[include]=businessDelegate`;

                Dealership.findById(this.props.id, params).then(result => {
                    if (result.status) {
                        const model = result.data;
                        newState.model = model;
                        newState.formValues = Object.assign(this.state.formValues, {
                            name: model.name,
                            dealershipManagerId: model.dealershipManagerId,
                            businessDelegateId: model.businessDelegateId,
                            enabled: model.enabled
                        });
                    }

                    callback(null, result.status);
                }).catch(e => callback(e, false));
            };
        }

        parallel(tasks, (err, result) => {
            if (this.isEditMode()) {
                if (!result.model) {
                    Alert.error('Concesionario inválido');
                    this.props.history.push(DealershipForm.INDEX_ROUTE);
                }
            }

            this.setState(newState);
        });
    }

    getBusinessDelegateOptions = () => {
        let options = [
            {value: '', label: 'Selecciona...'}
        ];

        this.state.businessDelegates.forEach(businessDelegate => {
            options.push({value: businessDelegate.id, label: [businessDelegate.firstName, businessDelegate.lastName].join(' ')});
        });

        return options;
    };

    getDealershipManagersOptions = () => {
        let options = [
            {value: '', label: 'Selecciona...'}
        ];

        this.state.dealershipManagers.forEach(dealershipManager => {
            options.push({value: dealershipManager.id, label: [dealershipManager.firstName, dealershipManager.lastName].join(' ')});
        });

        return options;
    };

    isEditMode = () => this.props.mode === Constants.Form.MODE_EDIT;

    onCancel = () => this.props.history.push(DealershipForm.INDEX_ROUTE);

    onChangeDealershipManagerId = option => {
        this.setState({formValues: Object.assign(this.state.formValues, {dealershipManagerId: option.value})});
    };

    onChangeBusinessDelegateId = option => {
        this.setState({formValues: Object.assign(this.state.formValues, {businessDelegateId: option.value})});
    };

    onChangeName = e => this.setState({formValues: Object.assign(this.state.formValues, {name: e.target.value})});

    onChangeEnabled = e => this.setState({
        formValues: Object.assign(this.state.formValues, {enabled: e.target.value})
    });

    onSave = () => {
        const constraints = {
            name: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el nombre'
                }
            },
            enabled: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el estado'
                }
            }
        };
        const result = validate(this.state.formValues, constraints, {format: 'flat', fullMessages: false});

        if (isArray(result)) {
            Alert.error(result.join('<br>'));
        } else {
            this.setState({processing: true}, () => {
                let initialData = {
                    name: '',
                    businessDelegateId: null,
                    dealershipManagerId: null,
                    enabled: true
                };

                if (!this.isEditMode()) {
                    initialData.deleted = false;
                }

                let data = Object.assign(initialData, clone(this.state.formValues));
                const newState = {
                    processing: false
                };

                if (!data.dealershipManagerId) {
                    data.dealershipManagerId = this.isEditMode() ? false : null;
                }

                if (!data.businessDelegateId) {
                    data.businessDelegateId = this.isEditMode() ? false : null;
                }

                if (this.isEditMode()) {
                    Dealership.update(this.props.id, data).then(result => {
                        if (result.status) {
                            Alert.info('Concesionario editado');
                        } else {
                            Alert.error('Error. No se pudo editar el concesionario');
                        }
                    }).finally(() => this.setState(newState));
                } else {
                    Dealership.create(data).then(result => {
                        if (result.status) {
                            Alert.info('Concesionario creado');
                            this.props.history.push(DealershipForm.INDEX_ROUTE);
                        } else {
                            Alert.error('Error. No se pudo crear el concesionario');
                        }
                    }).finally(() => this.setState(newState));
                }
            });
        }
    };

    renderSubmitButton = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <div>
                <Button className="btn-derive" onClick={this.onCancel}>CANCELAR</Button>
                <Button className="btn-derive" onClick={this.onSave}>{this.isEditMode() ? 'GUARDAR' : 'CREAR'}</Button>
            </div>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <Form>
                <div className="row flex">
                    <div className="col-sm-10 col-2 pad0">
                        <div className="row flex">
                            <div className="col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="name">
                                            <img alt="" src="/img/svg/icon_tienda.svg" width="20"/>
                                        </Label>
                                        <Input id="name"
                                               name="name"
                                               onChange={this.onChangeName}
                                               placeholder="Nombre *"
                                               type="text"
                                               value={this.state.formValues.name}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="dealershipManagerId">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Dropdown id="dealershipManagerId"
                                                  name="dealershipManagerId"
                                                  className={"form-control form-control-dropdown"}
                                                  onChange={this.onChangeDealershipManagerId}
                                                  options={this.getDealershipManagersOptions()}
                                                  placeholder="Gerente de concesionario"
                                                  value={this.state.formValues.dealershipManagerId}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="businessDelegateId">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Dropdown id="businessDelegateId"
                                                  name="businessDelegateId"
                                                  className={"form-control form-control-dropdown"}
                                                  onChange={this.onChangeBusinessDelegateId}
                                                  options={this.getBusinessDelegateOptions()}
                                                  placeholder="Delegado comercial"
                                                  value={this.state.formValues.businessDelegateId}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="enabled">
                                            <img alt="" src="/img/svg/icon_tipodecompra.svg" width="20"/>
                                        </Label>
                                        <Input id="enabled"
                                               name="enabled"
                                               onChange={this.onChangeEnabled}
                                               type="select"
                                               value={this.state.formValues.enabled}
                                        >
                                            <option value="">Selecciona...</option>
                                            <option value="true">Habilitado</option>
                                            <option value="false">Deshabilitado</option>
                                        </Input>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 col-2 pad0">
                        <div className="flex flex-btns">
                            {this.renderSubmitButton()}
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default withRouter(DealershipForm);
