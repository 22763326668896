import '@devexpress/dx-react-grid';
    import {FilteringState, IntegratedFiltering} from '@devexpress/dx-react-grid';
import {Grid, Table, TableHeaderRow} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import {Wave} from 'better-react-spinkit';
import clone from 'lodash/clone';
import React from 'react';
import {IconContext} from 'react-icons';
import {FaEye} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import Constants from '../../lib/Constants';
import Session from '../../lib/Session';
import Lead from '../../models/Lead';
import User from '../../models/User';

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            columns: [
                {
                    names: 'actions',
                    title: 'Acción',
                    getCellValue: row => (
                    <IconContext.Provider value={{color: '#E30012'}}>
                        <Link className="pl-1" to={`/call-center/${row.id}`}>
                            <FaEye/>    
                        </Link> 
                    </IconContext.Provider>)
                    
                },
                {
                    name: 'id',
                    title: 'ID'
                },
                {
                    name: 'firstName',
                    title: 'Nombres'
                },
                {
                    name: 'lastName',
                    title: 'Apellidos'
                },
                {
                    name: 'email',
                    title: 'Correo'
                },
                {
                    name: 'documentNumber',
                    title: 'Nº documento'
                },
                {
                    name: 'phone',
                    title: 'Teléfono fijo'
                },
                {
                    name: 'mobilePhone',
                    title: 'Teléfono móvil'
                },
                {
                    name: 'brand.name',
                    title: 'Marca'
                }
            ],
            columnExtensions: [
                {columnName: 'actions', filteringEnabled: false}
            ],
            rows: []
        };
    }

    componentDidMount() {
        User.me().then(userResult => {
            const userId = userResult.data.id;
            let channel = this.props.pusher.subscribe(`callCenter-${userId}`);

            channel.bind('new-lead', data => {
                const leadId = data.leadId;
                const params = `filter[include]=brand`;

                Lead.findById(leadId, params).then(result => {
                    if (result.status) {
                        let rows = clone(this.state.rows);

                        rows.push(result.data);

                        this.setState({rows: rows});
                    }
                });
            });

            const user = Session.getUser();
            const status = Constants.Lead.STATUS_CHECKED;
            const url = `filter[where][callCenterUserId]=${user.id}&filter[limit]=800&filter[order][0]=createdAt DESC&filter[where][status]=${status}&filter[include]=brand`;

            Lead.find(url).then(result => {
                if (result.status) {
                    this.setState({loading: false, rows: result.data});
                }
            });
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">CALL CENTER</h2>
                                <div className="subline"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Grid rows={this.state.rows} columns={this.state.columns}>
                                    <FilteringState columnExtensions={this.state.columnExtensions}/>
                                    <Table/>
                                    <TableHeaderRow/>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
