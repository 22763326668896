import {FilteringState, IntegratedFiltering} from '@devexpress/dx-react-grid';
import {Grid, Table, TableColumnResizing, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import {Wave} from 'better-react-spinkit';
import {isNil} from 'lodash';
import moment from 'moment';
import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Constants from '../../../lib/Constants';
import Utils from '../../../lib/Utils';
import SystemIssue from '../../../models/SystemIssue';

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            columns: [
                {
                    name: 'typeName',
                    title: 'Tipo',
                    getCellValue: row => !isNil(row.type) ? Utils.getSystemIssueTypeName(row.type) : ''
                },
                {
                    name: 'data',
                    title: 'Información',
                    getCellValue: row => {
                        let content = '';

                        if (!isNil(row.type)) {
                            if (!isNil(row.data)) {
                                if (row.type === Constants.SystemIssue.TYPE_FAILED_LEAD) {
                                    content = `ID: ${row.id}`;
                                }
                            }
                        }

                        return content;
                    }
                },
                {
                    name: 'createdAt',
                    title: 'Fecha y hora',
                    getCellValue: row => !isNil(row.createdAt) ? moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss a') : ''
                },
                {
                    name: 'statusName',
                    title: 'Estado',
                    getCellValue: row => !isNil(row.status) ? Utils.getSystemIssueStatusName(row.status) : ''
                }
            ],
            columnExtensions: [
                {columnName: 'actions', filteringEnabled: false}
            ],
            defaultColumnWidths: [
                {columnName: 'actions', width: 80},
                {columnName: 'typeName', width: 150},
                {columnName: 'data', width: 320},
                {columnName: 'createdAt', width: 200},
                {columnName: 'statusName', width: 100}
            ],
            rows: []
        };
    }

    componentDidMount() {
        const params = `filter[order]=createdAt DESC`;

        SystemIssue.find(params).then(result => {
            if (result.status) {
                this.setState({loading: false, rows: result.data});
            }
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">INCIDENCIAS</h2>
                                <div className="subline"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Grid rows={this.state.rows} columns={this.state.columns}>
                                    <FilteringState columnExtensions={this.state.columnExtensions}/>
                                    <IntegratedFiltering/>
                                    <Table/>
                                    <TableColumnResizing defaultColumnWidths={this.state.defaultColumnWidths}/>
                                    <TableHeaderRow/>
                                    <TableFilterRow/>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
