import {
  CustomPaging,
  FilteringState,
  IntegratedFiltering,
  PagingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  PagingPanel,
  Table,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { Wave } from "better-react-spinkit";
import { has, isEmpty, isNil, isUndefined } from "lodash";
import moment from "moment";
import "moment/locale/es";
import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
// noinspection ES6CheckImport
import { formatDate, parseDate } from "react-day-picker/moment";
import { DebounceInput } from "react-debounce-input";
import { FormGroup, Label } from "reactstrap";
import replaceString from "replace-string";
import Alert from "../../../lib/Alert";
import Api from "../../../lib/models/Api";
import Constants from "../../../lib/Constants";
import Session from "../../../lib/Session";
import Utils from "../../../lib/Utils";
import Lead from "../../../models/Lead";
import SalesManager from "../../../models/SalesManager";
import { Link } from "react-router-dom";
import User from "../../../models/User";
import Create from "./Create";
import Dealership from "../../../models/Dealership";
import Store from "../../../models/Store";
import Axios from "axios";

class Index extends React.Component {
  static user = Session.getUser();

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      prevParams: "",
      params: "",
      isBackofficeUser:
        Create.user.role === Constants.Role.BACKOFFICE ? true : false,
      columns: [
        {
          name: "id",
          title: "ID",
        },
        {
          name: "callCenterUserFullname",
          title: "Asesor de call center",
          getCellValue: (row) =>
            !isNil(row.callCenterUser)
              ? [
                  row.callCenterUser.firstName,
                  row.callCenterUser.lastName,
                ].join(" ")
              : "",
        },
        {
          name: "email",
          title: "Correo electrónico",
        },
        {
          name: "firstName",
          title: "Nombres",
        },
        {
          name: "lastName",
          title: "Apellidos",
        },
        {
          name: "documentType",
          title: "Tipo de Documento",
        },
        {
          name: "documentNumber",
          title: "Nº documento",
        },
        {
          name: "phone",
          title: "Teléfono fijo",
        },
        {
          name: "mobilePhone",
          title: "Teléfono móvil",
        },
        {
          name: "comment",
          title: "Comentario",
        },
        {
          name: "storeName",
          title: "Tienda",
          getCellValue: (row) => (!isNil(row.store) ? row.store.name : ""),
        },
        {
          name: "brandName",
          title: "Marca",
          getCellValue: (row) => (!isNil(row.brand) ? row.brand.name : ""),
        },
        {
          name: "model1Name",
          title: "Modelo 1",
          getCellValue: (row) =>
            !isNil(row.vehicleModels) &&
            !isEmpty(row.vehicleModels) &&
            has(row.vehicleModels, "[0].name")
              ? row.vehicleModels[0].name
              : "",
        },
        {
          name: "model2Name",
          title: "Modelo 2",
          getCellValue: (row) =>
            !isNil(row.vehicleModels) &&
            !isEmpty(row.vehicleModels) &&
            has(row.vehicleModels, "[1].name")
              ? row.vehicleModels[1].name
              : "",
        },
        {
          name: "model3Name",
          title: "Modelo 3",
          getCellValue: (row) =>
            !isNil(row.vehicleModels) &&
            !isEmpty(row.vehicleModels) &&
            has(row.vehicleModels, "[2].name")
              ? row.vehicleModels[2].name
              : "",
        },
        {
          name: "createdAt",
          title: "Fecha de creación",
          getCellValue: (row) =>
            !isNil(row.createdAt)
              ? moment(row.createdAt).format("DD/MM/YYYY hh:mm:ss a")
              : "",
        },
        {
          name: "assignedAt",
          title: "Fecha de derivación",
          getCellValue: (row) =>
            !isNil(row.assignedAt)
              ? moment(row.assignedAt).format("DD/MM/YYYY hh:mm:ss a")
              : "",
        },
        {
          name: "backOfficeUserId",
          title: "Backoffice",
          getCellValue: (row) =>
            !isNil(row.backOfficeUser)
              ? [
                  row.backOfficeUser.firstName,
                  row.backOfficeUser.lastName,
                ].join(" ")
              : "",
        },
        {
          name: "origin",
          title: "Origen",
        },
        {
          name: "purchaseType",
          title: "Tipo de compra",
        },
        {
          name: "assignedUser1",
          title: "Derivación Nº 1",
          getCellValue: (row) =>
            !isNil(row.firstUser)
              ? [row.firstUser.firstName, row.firstUser.lastName].join(" ")
              : "",
        },
        {
          name: "assignedUser2",
          title: "Derivación Nº 2",
          getCellValue: (row) => {
            if (
              !isNil(row.reassignedUsers) &&
              !isEmpty(row.reassignedUsers) &&
              !isNil(row.reassignedUsers[0])
            ) {
              const reassignedUser = row.reassignedUsers[0];

              if (
                !isNil(row.firstUser) &&
                reassignedUser.id !== row.firstUser.id
              ) {
                return [reassignedUser.firstName, reassignedUser.lastName].join(
                  " "
                );
              }
            }

            return "";
          },
        },
        {
          name: "assignedUser3",
          title: "Derivación Nº 3",
          getCellValue: (row) => {
            if (!isNil(row.user) && !isNil(row.firstUser)) {
              if (
                (isNil(row.reassignedUsers) || isEmpty(row.reassignedUsers)) &&
                row.user.id !== row.firstUser.id
              ) {
                return [row.user.firstName, row.user.lastName].join(" ");
              }
            }

            return "";
          },
        },
        {
          name: "salesUserFullname",
          title: "Vendedor",
          getCellValue: (row) =>
            !isNil(row.user)
              ? [row.user.firstName, row.user.lastName].join(" ")
              : "",
        },
        {
          name: "wantsVisit",
          title: "¿Visita?",
          getCellValue: (row) => {
            if (
              !isNil(row.status) &&
              row.status === Constants.Lead.STATUS_ATTENDED
            ) {
              return row.wantsVisit ? "Sí" : "No";
            }

            return "";
          },
        },
        {
          name: "visit",
          title: "Visita",
          getCellValue: (row) => {
            if (
              !isNil(row.status) &&
              row.status === Constants.Lead.STATUS_ATTENDED
            ) {
              if (row.wantsVisit) {
                return moment(row.visit.scheduledAt).format(
                  "DD/MM/YYYY hh:mm:ss a"
                );
              }
            }

            return "";
          },
        },
        {
          name: "visitTestDrive",
          title: "¿Test drive (visita)?",
          getCellValue: (row) => {
            if (
              !isNil(row.status) &&
              row.status === Constants.Lead.STATUS_ATTENDED
            ) {
              if (row.wantsVisit) {
                return row.visit.testDrive ? "Sí" : "No";
              }
            }

            return "";
          },
        },
        {
          name: "contactAgain",
          title: "¿Contacto?",
          getCellValue: (row) => {
            if (
              !isNil(row.status) &&
              row.status === Constants.Lead.STATUS_ATTENDED
            ) {
              return row.contactAgain ? "Sí" : "No";
            }

            return "";
          },
        },
        {
          name: "contact",
          title: "Contacto",
          getCellValue: (row) => {
            if (
              !isNil(row.status) &&
              row.status === Constants.Lead.STATUS_ATTENDED
            ) {
              if (row.contactAgain) {
                return moment(row.contact.scheduledAt).format(
                  "DD/MM/YYYY hh:mm:ss a"
                );
              }
            }

            return "";
          },
        },
        {
          name: "contactTestDrive",
          title: "¿Test drive (contacto)?",
          getCellValue: (row) => {
            if (
              !isNil(row.status) &&
              row.status === Constants.Lead.STATUS_ATTENDED
            ) {
              if (row.contactAgain) {
                return row.contact.testDrive ? "Sí" : "No";
              }
            }

            return "";
          },
        },
        {
          name: "reason",
          title: "Motivo de no",
          getCellValue: (row) => {
            if (
              !isNil(row.status) &&
              row.status === Constants.Lead.STATUS_ATTENDED
            ) {
              if (!row.wantsVisit && !row.contactAgain) {
                return row.reason;
              }
            }

            return "";
          },
        },
        {
          name: "sold",
          title: "Feedback",
          getCellValue: (row) => {
            if (
              !isNil(row.status) &&
              row.status === Constants.Lead.STATUS_ATTENDED
            ) {
              return row.sold === null
                ? "Pendiente"
                : row.sold
                ? "Vendido"
                : "No vendido";
            }

            return "";
          },
        },
        {
          name: "status",
          title: "Estado",
          getCellValue: (row) =>
            !isNil(row.status) ? Utils.getLeadStatusName(row.status) : "",
        },
        {
          name: "mantraSent",
          title: "enviado Whatsapps",
          getCellValue: (row) => {
            return row.status === Constants.Lead.STATUS_DISCARDED &&
              row.hasOwnProperty("mantraSent")
              ? row.mantraSent
                ? "enviado"
                : "no enviado"
              : "";
          },
        },
      ],
      defaultColumnWidths: [
        { columnName: "id", width: 240 },
        { columnName: "callCenterUserFullname", width: 360 },
        { columnName: "email", width: 360 },
        { columnName: "firstName", width: 240 },
        { columnName: "lastName", width: 240 },
        { columnName: "documentType", width: 240 },
        { columnName: "documentNumber", width: 240 },
        { columnName: "phone", width: 240 },
        { columnName: "mobilePhone", width: 240 },
        { columnName: "comment", width: 240 },
        { columnName: "storeName", width: 240 },
        { columnName: "brandName", width: 240 },
        { columnName: "model1Name", width: 240 },
        { columnName: "model2Name", width: 240 },
        { columnName: "model3Name", width: 240 },
        { columnName: "createdAt", width: 240 },
        { columnName: "assignedAt", width: 240 },
        { columnName: "origin", width: 240 },
        { columnName: "backOfficeUserId", width: 240 },
        { columnName: "purchaseType", width: 180 },
        { columnName: "assignedUser1", width: 240 },
        { columnName: "assignedUser2", width: 240 },
        { columnName: "assignedUser3", width: 240 },
        { columnName: "salesUserFullname", width: 360 },
        { columnName: "wantsVisit", width: 180 },
        { columnName: "visit", width: 240 },
        { columnName: "visitTestDrive", width: 240 },
        { columnName: "contactAgain", width: 180 },
        { columnName: "contact", width: 240 },
        { columnName: "contactTestDrive", width: 240 },
        { columnName: "reason", width: 360 },
        { columnName: "sold", width: 180 },
        { columnName: "status", width: 180 },
        { columnName: "mantraSent", width: 180 },
      ],
      rows: [],
      totalCount: 0,
      pageSize: 100,
      currentPage: 0,
      /* Filters */
      startDate: "",
      endDate: "",
      searchText: "",
    };
  }

  componentDidMount() {
    const user = Session.getUser();

    // Set newState
    const newState = {
      loading: false,
    };

    console.log("El role es: " + user.role);

    switch (user.role) {
      case Constants.Role.SALES_MANAGER:
        // Set params
        const params = `filter[where][userId]=${user.id}`;
        // Search sales manager by userId
        SalesManager.find(params)
          .then((result) => {
            // If we have success result set params
            // else notify failed load
            if (result.status) {
              let salesManagers = result.data;
              // Use a fake condition by default
              newState.prevParams = `filter[where][storeId]=abc`;
              let parts = [];

              if (salesManagers.length === 1) {
                // Check if it has brand IDs
                if (salesManagers[0].brandIds.length) {
                  parts.push(
                    `filter[where][storeId]=${salesManagers[0].storeId}`
                  );

                  if (salesManagers[0].brandIds.length === 1) {
                    parts.push(
                      `filter[where][brandId]=${salesManagers[0].brandIds[0]}`
                    );
                  } else {
                    parts.push(
                      salesManagers[0].brandIds
                        .map(
                          (brandId, i) =>
                            `filter[where][brandId][inq][${i}]=${brandId}`
                        )
                        .filter((x) => Utils.isUsable(x))
                        .join("&")
                    );
                  }
                }
              } else {
                salesManagers.forEach((salesManager, i) => {
                  // Check if every sales manager has brand IDs
                  if (salesManagers[i].brandIds.length) {
                    parts.push(
                      `filter[where][or][${i}][storeId]=${salesManagers[i].storeId}`
                    );

                    if (salesManagers[i].brandIds.length === 1) {
                      parts.push(
                        `filter[where][or][${i}][brandId]=${salesManagers[i].brandIds[0]}`
                      );
                    } else {
                      parts.push(
                        salesManagers[i].brandIds
                          .map(
                            (brandId, j) =>
                              `filter[where][or][${i}][brandId][inq]=${brandId}`
                          )
                          .filter((x) => Utils.isUsable(x))
                          .join("&")
                      );
                    }
                  }
                });
              }

              if (!isEmpty(parts)) {
                newState.prevParams = parts.join("&");
              }

              // Set state and load data
              this.setState(newState, () => this.load());
            } else {
              this.notifyAboutFailedLoad();
            }
          })
          .catch((e) => {
            console.log(e);
            this.notifyAboutFailedLoad();
          });

        break;
      case Constants.Role.BACKOFFICE:
        // Add userId to backOfficeUserId param
        newState.prevParams = `filter[where][backOfficeUserId]=${user.id}`;

        // Set state and load data
        this.setState(newState, () => this.load());

        break;
      case Constants.Role.DEALERSHIP_MANAGER:
        // Set params
        const dealershipParams = `filter[where][dealershipManagerId]=${user.id}`;
        // Search dealerships by businessDelegateId
        Dealership.find(dealershipParams)
          .then((dealershipsResult) => {
            // If we have success result set params
            // else notify failed load
            if (dealershipsResult.status) {
              let dealerships = dealershipsResult.data;
              let dealershipIds = [];

              // Get dealershipIds from the found dealerships
              dealerships.forEach((dealership) => {
                dealershipIds.push(dealership.id);
              });

              console.log(dealershipIds);

              if (dealershipIds.length > 0) {
                // Use a fake condition by default
                newState.prevParams = `filter[where][storeId]=abc`;
                let parts = [];

                const whereConditions = dealershipIds.map((id) => ({
                  dealershipId: id,
                }));

                // Search stores by dealershipId
                Axios.get(process.env.REACT_APP_BASE_URL + "/stores", {
                  params: {
                    filter: {
                      where: {
                        dealershipId: {
                          inq: dealershipIds,
                        },
                      },
                    },
                  },
                })
                  .then((response) => {
                    const stores = response.data;

                    if (stores) {
                      console.log(stores);

                      // Add filters for brandIds in parts
                      stores.forEach((store, i) => {
                        console.log(store.id);
                        parts.push(
                          `filter[where][or][${i}][storeId]=${store.id}`
                        );
                      });

                      if (!isEmpty(parts)) {
                        newState.prevParams = parts.join("&");
                      }
                    }

                    // Set state and load data
                    this.setState(newState, () => this.load());
                  })
                  .catch((error) => {
                    console.log(error);
                    this.notifyAboutFailedLoad();
                  });
              } else {
                // Set state and load data
                this.setState(newState, () => this.load());
              }
            } else {
              this.notifyAboutFailedLoad();
            }
          })
          .catch((dealershipsError) => {
            console.log(dealershipsError);
            this.notifyAboutFailedLoad();
          });

        break;

      default:
        this.load();
        break;
    }
  }

  getFilterParams = (count = false) => {
    const parts = [];
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;

    if (startDate && endDate) {
      parts.push(
        `filter[where][createdAt][between][0]=${startDate}&filter[where][createdAt][between][1]=${endDate}`
      );
    } else if (startDate && !endDate) {
      parts.push(`filter[where][createdAt][gte]=${startDate}`);
    } else if (!startDate && endDate) {
      parts.push(`filter[where][createdAt][lte]=${endDate}`);
    }

    const searchText = this.state.searchText;

    if (searchText) {
      parts.push(
        `filter[where][or][0][id]=${searchText}&filter[where][or][1][$text][search]=${searchText}`
      );
    }

    let filterParams = "";

    if (!isEmpty(parts)) {
      filterParams = parts.join("&");

      if (count) {
        filterParams = replaceString(filterParams, "filter[where]", "where");
      }
    }

    return filterParams;
  };

  getParams = () => {
    let params = `filter[include]=callCenterUser&filter[include]=user&filter[include]=brand&filter[include]=store&filter[include]=vehicleModels&filter[include]=firstUser&filter[include]=reassignedUsers&filter[include]=backOfficeUser&filter[order]=createdAt DESC`;
    const filterParams = this.getFilterParams();

    if (filterParams !== "") {
      params += "&" + filterParams;
    }

    params += `&filter[limit]=${this.state.pageSize}&filter[skip]=${this.state.currentPage}`;

    return params;
  };

  getQueryString = () =>
    [this.state.prevParams, this.getParams()]
      .filter((x) => Utils.isUsable(x))
      .join("&");

  load = (force = false) => {
    const params = this.getQueryString();

    if (this.state.params !== params || force) {
      this.setState({ loading: true }, () => {
        const newState = {
          loading: false,
          params: params,
          totalCount: 0,
        };
        const filterPararams = [
          replaceString(this.state.prevParams, "filter[where]", "where"),
          this.getFilterParams(true),
        ]
          .filter((x) => Utils.isUsable(x))
          .join("&");

        Lead.count(filterPararams)
          .then((countResult) => {
            if (countResult.status) {
              newState.totalCount = countResult.data.count;
            }

            Lead.find(params)
              .then((result) => {
                if (result.status) {
                  newState.rows = result.data;
                }

                this.setState(newState);
              })
              .catch(() => this.setState(newState));
          })
          .catch(() => this.setState(newState));
      });
    }
  };

  notifyAboutFailedLoad = () => Alert.error("Lo sentimos, ocurrió un error");

  onCurrentPageChange = (currentPage) =>
    this.setState({ loading: true, currentPage: currentPage }, () =>
      this.load()
    );

  onChangeSearchText = (e) =>
    this.setState({ searchText: e.target.value }, () => this.load());

  onChangeStartDate = (day) =>
    this.setState(
      { startDate: isUndefined(day) ? day : Utils.formatDate(day) },
      () => this.load()
    );

  onChangeEndDate = (day) =>
    this.setState(
      {
        endDate: isUndefined(day)
          ? day
          : Utils.formatDate(day, "YYYY-MM-DD", "T23:59:59.999Z"),
      },
      () => this.load()
    );

  onDownload = (e) => {
    e.preventDefault();

    const accessToken = Session.getCookie();
    let url = `${Api.baseUrl}${Lead.endpoint}/exports?access_token=${accessToken}`;
    const params = this.getQueryString();

    if (Utils.isUsable(params)) {
      url += "&" + params;
    }

    window.open(url, "_blank");
  };

  onUpload = (e) => {
    e.preventDefault();

    document.getElementById("upload-excel").click();
  };

  onChangeFile = async (e) => {
    const file = e.target.files[0];
    const result = await Lead.import(file);
    if (result.status) {
      this.load(true);
      Alert.info("Carga de leads finalizada", {
        position: "top-right",
        effect: "slide",
        html: false,
        timeout: 1500,
      });
    }
  };

  onPageSizeChange = (pageSize) =>
    this.setState({ pageSize: pageSize }, () => this.load());

  renderTable = () => {
    if (this.state.loading) {
      return <Wave color="#D81626" size={50} />;
    }

    return (
      <Grid rows={this.state.rows} columns={this.state.columns}>
        <FilteringState />
        <PagingState
          currentPage={this.state.currentPage}
          onCurrentPageChange={this.onCurrentPageChange}
          onPageSizeChange={this.onPageSizeChange}
          pageSize={this.state.pageSize}
        />
        <CustomPaging totalCount={this.state.totalCount} />
        <IntegratedFiltering />
        <Table />
        <TableColumnResizing
          defaultColumnWidths={this.state.defaultColumnWidths}
        />
        <TableHeaderRow />
        <TableFilterRow messages={{ filterPlaceholder: "Filtro..." }} />
        <PagingPanel pageSizes={[20, 50, 100]} />
      </Grid>
    );
  };

  render() {
    return (
      <div className="container content" style={{ maxWidth: "100%" }}>
        <div className="row flex mrgBtm30">
          <div className="col-sm-12">
            <div className="flex-between">
              <div>
                <h2 className="dates-client">LEADS</h2>
                <div className="subline" />
              </div>

              {/* Create Lead Button - Check if is backoffice user roles to show */}
              {this.state.isBackofficeUser ? (
                <div>
                  <Link to="/leads/create">
                    <img alt="" src="/img/svg/icon_mas_rojo.svg" width="25" />
                  </Link>
                </div>
              ) : null}
              {/* Create Lead Button - Check if is backoffice user roles to show */}

              <input
                type="file"
                id="upload-excel"
                name="upload-file"
                accept=".xlsx"
                ngf-max-size="2MB"
                onChange={this.onChangeFile}
                style={{ overflow: "hidden", width: "0px" }}
              ></input>
              <a href="/" onClick={this.onUpload}>
                <img
                  alt="Importar"
                  className="icon_excel mr-2"
                  src="/img/svg/upload-excel.svg"
                  width="30"
                />
              </a>
              <div className="InputDatepicker">
                <FormGroup className="form-group">
                  <img src="/img/svg/icon_fechahora.svg" width="30" alt="" />
                  <DayPickerInput
                    id="startDate"
                    name="startDate"
                    inputProps={{ className: "form-control" }}
                    firstDayOfWeek={1}
                    format="YYYY-MM-DD"
                    formatDate={formatDate}
                    locale="es"
                    months={Constants.DayPicker.MONTHS}
                    onDayChange={this.onChangeStartDate}
                    parseDate={parseDate}
                    placeholder="Fecha inicial"
                    weekdaysLong={Constants.DayPicker.WEEKDAYS_LONG}
                    weekdaysShort={Constants.DayPicker.WEEKDAYS_SHORT}
                  />
                  <DayPickerInput
                    id="endDate"
                    name="endDate"
                    inputProps={{ className: "form-control" }}
                    firstDayOfWeek={1}
                    format="YYYY-MM-DD"
                    formatDate={formatDate}
                    locale="es"
                    months={Constants.DayPicker.MONTHS}
                    onDayChange={this.onChangeEndDate}
                    parseDate={parseDate}
                    placeholder="Fecha final"
                    weekdaysLong={Constants.DayPicker.WEEKDAYS_LONG}
                    weekdaysShort={Constants.DayPicker.WEEKDAYS_SHORT}
                  />
                </FormGroup>
              </div>
              <div className="InputSearch">
                <FormGroup className="form-group">
                  <div className="icon-addon addon-lg">
                    <Label className="glyphicon" for="searchText">
                      <img alt="" src="/img/svg/icon_buscar.svg" width="20" />
                    </Label>
                    <DebounceInput
                      id="searchText"
                      name="searchText"
                      className="form-control"
                      debounceTimeout={300}
                      minLength={2}
                      onChange={this.onChangeSearchText}
                    />
                  </div>
                </FormGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">{this.renderTable()}</div>
              <div className="col-md-12 mt-4">
                <div className="d-flex justify-content-end align-items-center">
                  <div>
                    <a href="/" onClick={this.onDownload}>
                      <img
                        alt="Exportar"
                        className="icon_excel mr-2"
                        src="/img/svg/icon_excel.svg"
                        width="30"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
