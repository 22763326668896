class Constants {
    static Channels = {
        BACK_OFFICE: 'backOffice'
    };
    static DayPicker = {
        MONTHS: [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Setiembre',
            'Octubre',
            'Noviembre',
            'Diciembre'
        ],
        WEEKDAYS_LONG: [
            'Domingo',
            'Lunes',
            'Martes',
            'Miércoles',
            'Jueves',
            'Viernes',
            'Sábado'
        ],
        WEEKDAYS_SHORT: [
            'Dom',
            'Lun',
            'Mar',
            'Mie',
            'Jue',
            'Vie',
            'Sab'
        ]
    };
    static Form = {
        MODE_CREATE: 'create',
        MODE_EDIT: 'edit'
    };
    static Incentive = {
        STATUS_ACTIVE: 'active',
        STATUS_INACTIVE: 'inactive'
    };
    static IncentiveRequest = {
        STATUS_PENDING: 'pending',
        STATUS_ACCEPTED: 'accepted',
        STATUS_DENIED: 'denied'
    };
    static Lead = {
        STATUS_CREATED: 'created',
        STATUS_CHECKED: 'checked',
        STATUS_DISCARDED: 'discarded',
        STATUS_FAILED: 'failed',
        STATUS_UNATTENDED: 'unattended',
        STATUS_ATTENDED: 'attended',
        STATUS_LOST: 'lost'
    };
    static Report = {
        LEADS_PER_MODEL: 'MCRWeoIONUad2P',
        LEADS_ASSIGNED: 'n6Ageymgmv0j1c',
        LEADS_ATTENDED: 'HBGwfcro9qDawX',
        LEADS_SCHEDULED: 'kDPNequwlqkCYq',
        LEADS_PER_DEALERSHIP: 'VmyX9ULtYnjAJp',
        LEADS_UNATTENDED: 'RSTUVWXYZ',
        RANKING_PER_DEALERSHIP: 'qAjvHMaOhEGqpy',
        LEADS_PER_MONTH: 'ABCEFGHIJKLM'
        
    };
    static Role = {
        ROOT: 'root',
        WEB_SERVICE: 'webService',
        ADMIN: 'admin',
        COORDINATOR: 'coordinator',
        MAIN_MANAGER: 'mainManager',
        BUSINESS_MANAGER: 'businessManager',
        MARKETING_MANAGER: 'marketingManager',
        BUSINESS_DELEGATE: 'businessDelegate',
        DEALERSHIP_MANAGER: 'dealershipManager',
        SALES_MANAGER: 'salesManager',
        SALES: 'sales',
        CALL_CENTER_SUPERVISOR: 'callCenterSupervisor',
        CALL_CENTER: 'callCenter',
        BACKOFFICE: 'backoffice',
    };
    static SystemIssue = {
        STATUS_UNSOLVED: 'unsolved',
        STATUS_SOLVING: 'solving',
        STATUS_SOLVED: 'solved',
        STATUS_DISCARDED: 'discarded',
        TYPE_FAILED_LEAD: 'failedLead'
    };
}

export default Constants;
