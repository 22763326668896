import React from 'react';
import IncentiveForm from '../../../components/forms/IncentiveForm';
import Constants from '../../../lib/Constants';

class Edit extends React.Component {
    render() {
        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">EDITAR INCENTIVO</h2>
                                <div className="subline"/>
                            </div>
                        </div>
                    </div>
                </div>
                <IncentiveForm id={this.props.match.params.id} mode={Constants.Form.MODE_EDIT}/>
            </div>
        );
    }
}

export default Edit;
