import _IndexTable  from './_IndexTable';
import Store from '../../../../models/Store'

export default class StoreTable extends _IndexTable {
  
  constructor(props)
  {
    super(props);
    this.config = this.config.bind(this);
    this.submitChange = this.submitChange.bind(this);
    
  }
 
  config()
  {
     this.setState({app:{
       name_table:"Consesionarias",
       name_column:"Nombre concesionaria"
     }})
  }
  
  /**
   * [OVERRIDE] retorna el nombre de la columna
   * @returns {string}
   */
   getNameColumn()
   {
     return "Nombre Tienda";
   }

  getData()
  {
    const params = 'filter[where][enabled]=true&filter[where][deleted]=false'
    Store.find(params).then(x => {this.setState({rows: x.data})})
  }

  async submitChange(id,coti_name)
  {
    var temp = await  Store.changeCotiName(id,coti_name);
    var data = await Store.find();
    this.setState({rows: data.data})
  }
}