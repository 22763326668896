import {Wave} from 'better-react-spinkit';
import React from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button} from 'reactstrap';
import Alert from '../../lib/Alert';
import Constants from '../../lib/Constants';
import IncentiveRequest from '../../models/IncentiveRequest';
import Store from '../../models/Store';

class IncentiveRequestItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            incentiveRequest: this.props.incentiveRequest,
            status: this.props.incentiveRequest.status,
            store: {
                name: ''
            },
            processing: false
        };
    }

    componentDidMount() {
        const storeId = this.state.incentiveRequest.user.storeId;
        const params = `filter[include]=district`;
        const newState = {
            loading: false
        };

        Store.findById(storeId, params).then(result => {
            if (result.status) {
                newState.store = result.data;
            }
        }).finally(() => this.setState(newState));
    }

    accept = () => {
        this.setState({processing: true}, () => {
            const id = this.state.incentiveRequest.id;
            const data = {
                status: Constants.IncentiveRequest.STATUS_ACCEPTED
            };
            const newState = {
                processing: false
            };

            IncentiveRequest.update(id, data).then(result => {
                if (result.status) {
                    newState.status = data.status;

                    Alert.info('Solicitud aceptada');
                } else {
                    Alert.error('Error. No se pudo aceptar la solicitud');
                }
            }).finally(() => this.setState(newState));
        });
    };

    deny = () => {
        this.setState({processing: true}, () => {
            const id = this.state.incentiveRequest.id;
            const data = {
                status: Constants.IncentiveRequest.STATUS_DENIED
            };
            const newState = {
                processing: false
            };

            IncentiveRequest.update(id, data).then(result => {
                if (result.status) {
                    newState.status = data.status;

                    Alert.info('Solicitud rechazada');
                } else {
                    Alert.error('Error. No se pudo rechazar la solicitud');
                }
            }).finally(() => this.setState(newState));
        });
    };

    onAccept = () => {
        confirmAlert({
            title: 'Aceptar solicitud',
            message: '¿Estás segur@?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => this.accept()
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    onDeny = () => {
        confirmAlert({
            title: 'Rechazar solicitud',
            message: '¿Estás segur@?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => this.deny()
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    renderButtons = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        if (this.state.status === Constants.IncentiveRequest.STATUS_PENDING) {
            return (
                <div className="col-sm-12">
                    <div className="flex-around">
                        <Button className="btn-accept text-center" onClick={this.onAccept}>ACEPTAR</Button>
                        <Button className="btn-accept text-center" onClick={this.onDeny}>DENEGAR</Button>
                    </div>
                </div>
            );
        }

        if (this.state.status === Constants.IncentiveRequest.STATUS_ACCEPTED) {
            return (
                <div className="col-sm-12">
                    <div className="flex-around">
                        <p>Aceptado</p>
                    </div>
                </div>
            );
        }

        if (this.state.status === Constants.IncentiveRequest.STATUS_DENIED) {
            return (
                <div className="col-sm-12">
                    <div className="flex-around">
                        <p>Rechazado</p>
                    </div>
                </div>
            );
        }
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        const user = this.state.incentiveRequest.user;
        const incentive = this.state.incentiveRequest.incentive;
        const store = this.state.store;
        const district = store ? store.district : null;

        return (
            <div className="boxIncentives">
                <div className="row">
                    <div className="col-sm-6">
                        <div>
                            <p>
                                <img alt="" className="mrgRight10" src="/img/svg/icon_user_r.svg" width="18"/>
                                {[user.firstName, user.lastName].join(' ')}
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div>
                            <p>
                                <img alt="" className="mrgRight10" src="/img/svg/icon_departamentos_r.svg" width="16"/>
                                {district ? district.reference : "N/A"}
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div>
                            <p>
                                <img alt="" className="mrgRight10" src="/img/svg/icon_regalo_r.svg" width="18"/>
                                {incentive.name}
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div>
                            <p>
                                <img alt="" className="mrgRight10" src="/img/svg/icon_tienda_r.svg" width="18"/>
                                {store.name != '' ? store.name : "N/A"}
                            </p>
                        </div>
                    </div>
                    {this.renderButtons()}
                </div>
            </div>
        );
    }
}

class IncentiveRequestList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            incentiveRequests: []
        };
    }

    componentDidMount() {
        const params = `filter[include]=user&filter[include]=incentive`;
        const newState = {
            loading: false
        };

        IncentiveRequest.find(params).then(result => {
            if (result.status) {
                newState.incentiveRequests = result.data;
            }
        }).finally(() => this.setState(newState));
    }

    render() {
        return (
            <div className="col-sm-6">
                <div className="flex-incentives">
                    <div>
                        <h2 className="dates-client">SOLICITUDES</h2>
                        <div className="subline"/>
                    </div>
                </div>
                {this.state.incentiveRequests.map(incentiveRequest =>
                    <IncentiveRequestItem key={`incentiveRequestItem-${incentiveRequest.id}`}
                                          incentiveRequest={incentiveRequest}
                    />
                )}
            </div>
        );
    }
}

export default IncentiveRequestList;
