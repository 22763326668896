import Api from '../lib/models/Api';

class VehicleModel extends Api {
    static endpoint = '/vehicle-models';

    static changeCotiName (id, coti_name)
    {
        return this.update(id + '/cotiName',{coti_name:coti_name})
    }
}

export default VehicleModel;
