import React from 'react';
import {Wave} from 'better-react-spinkit';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {IconContext} from 'react-icons';
import {FaLock} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import {Button} from 'reactstrap';
import Alert from '../../lib/Alert';
import Constants from '../../lib/Constants';
import Incentive from '../../models/Incentive';

class DisableIncentiveButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processing: false
        };
    }

    disable = () => {
        const id = this.props.id;

        this.setState({processing: true}, () => {
            Incentive.update(id, {status: Constants.Incentive.STATUS_INACTIVE}).then(result => {
                this.setState({processing: false}, () => this.props.onDisable(id, result));
            });
        });
    };

    onClick = e => {
        e.preventDefault();
        confirmAlert({
            title: 'Deshabilitar incentivo',
            message: '¿Estás segur@?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => this.disable()
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    render() {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <a href="/" onClick={this.onClick}>
                <IconContext.Provider value={{color: '#E30012'}}>
                    <FaLock/>
                </IconContext.Provider>
            </a>
        );
    }
}

class IncentiveList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            incentives: []
        };
    }

    componentDidMount() {
        const params = `filter[where][status]=${Constants.Incentive.STATUS_ACTIVE}&filter[order]=name ASC`;

        Incentive.find(params).then(result => {
            if (result.status) {
                this.setState({loading: false, incentives: result.data});
            }
        });
    }

    onDisable = (id, result) => {
        if (result.status) {
            const incentives = this.state.incentives.filter(incentive => incentive.id !== id);

            this.setState({incentives: incentives}, () => Alert.info('Incentivo deshabilitado'));
        } else {
            Alert.error('Error. No se pudo deshabilitar el incentivo');
        }
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className="col-sm-6">
                <div className="flex-incentives">
                    <div>
                        <h2 className="dates-client">INCENTIVOS</h2>
                        <div className="subline"/>
                    </div>
                    <div>
                        <Link to="/incentives/create">
                            <img alt="" src="/img/svg/icon_mas_rojo.svg" width="25"/>
                        </Link>
                    </div>
                </div>
                {this.state.incentives.map(incentive =>
                    <div key={`incentive-${incentive.id}`} className="boxIncentives">
                        <div className="row flex">
                            <div className="col-sm-4">
                                <img alt="" className="img-fluid" src={incentive.photoUrl}/>
                            </div>
                            <div className="col-sm-8">
                                <Link to={`/incentives/edit/${incentive.id}`}>
                                    <img alt="" className="icon_editar_gris" src="/img/svg/icon_editar_gris.svg" width="24"/>
                                </Link>
                                <div className="name">
                                    {incentive.name}
                                </div>
                                <div className="text">
                                    {incentive.description}
                                </div>
                                <div className="flex-around">
                                    <div>
                                        <DisableIncentiveButton id={incentive.id} onDisable={this.onDisable}/>
                                    </div>
                                    <div>
                                        <Button className="btn-points mrg0">{incentive.points} ptos</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"/>
                    </div>
                )}
            </div>
        );
    }
}

export default IncentiveList;
