import {Wave} from 'better-react-spinkit';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {isEmpty} from 'lodash';
import React from 'react';
import Constants from '../../lib/Constants';
import Utils from '../../lib/Utils';
import Report from '../../models/Report';

class LeadsPerModel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [],
            options: {},
            title: ''
        };
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loading: true}, () => this.load());
    }

    load = () => {
        let to = `/${Constants.Report.LEADS_PER_MODEL}`;
        const params = Utils.getReportParams(this.props.filter);

        if (!isEmpty(params)) {
            to += '?' + params.join('&');
        }

        Report.get({}, to).then(result => {
            const data = result.data;
            const options = {
                chart: {
                    backgroundColor: '#CED2D5',
                    shadow: {
                        color: 'rgba(0, 0, 0, 0.4)',
                        offsetX: 1,
                        offsetY: 1,
                        opacity: '0.3',
                        width: 5
                    },
                    width: 420,
                    height: 350,
                    animation: false,
                    type: 'bar'
                },
                colorAxis: {
                    min: 1,
                    max: 1000,
                },
                title: {
                    text: '',
                    y: 30
                },
                xAxis: {
                    minColor: '#EFECF3',
                    maxColor: '#990041',
                    categories: data.rows.map(row => row.name),
                    title: {
                        text: null
                    }
                },
                yAxis: {
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    },
                    series: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                series: [{
                    data: data.rows.map(row => {
                        return {
                            name: row.count,
                            y: row.count
                        };
                    }),
                    color: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                        },
                        stops: [
                            [0, '#A2A6A9'],
                            [1, '#292929']
                        ]
                    }
                }],
                tooltip: {
                    enabled: false
                }
            };

            this.setState({loading: false, data: data, options: options, title: `Por modelo (total: ${data.total})`})
        });
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className={"boxGraphics"}>
                <div>
                    <h4>{this.state.title}</h4>
                    <HighchartsReact highcharts={Highcharts} options={this.state.options}/>
                </div>
            </div>
        );
    }
}

export default LeadsPerModel;
