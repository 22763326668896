import Alert from './Alert';

class RealTime {
    static setChannels(pusherInstance, userId) {
        const channelName = `user-${userId}`;
        let channel = pusherInstance.subscribe(channelName);

        channel.bind('new-lead', data => {
            console.log('Lead data:', data);
            Alert.info('Nuevo lead');
        });
    }
}

export default RealTime;
