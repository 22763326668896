import {Wave} from 'better-react-spinkit';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import {clone, isArray} from 'lodash';
import React from 'react';
import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import {withRouter} from 'react-router-dom';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import validate from 'validate.js';
import Alert from '../../lib/Alert';
import Api from '../../lib/models/Api';
import Incentive from '../../models/Incentive';
import Session from '../../lib/Session';
import Constants from '../../lib/Constants';

registerPlugin(FilePondPluginImagePreview);

class IncentiveForm extends React.Component {
    static INDEX_ROUTE = '/users';

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            model: null,
            formValues: {
                name: '',
                description: '',
                points: '',
                photoFilename: '',
                photoUrl: ''
            },
            files: [],
            deleteUrl: '',
            processing: false
        };
    }

    componentDidMount() {
        if (this.isEditMode()) {
            let newState = {
                loading: false
            };

            Incentive.findById(this.props.id).then(result => {
                if (result.status) {
                    const model = result.data;
                    newState = Object.assign(newState, {
                        model: model,
                        formValues: {
                            name: model.name,
                            description: model.description,
                            points: model.points,
                            photoFilename: model.photoFilename,
                            photoUrl: model.photoUrl
                        }
                    });
                } else {
                    Alert.error('Incentivo inválido');
                    this.props.history.push(IncentiveForm.INDEX_ROUTE);
                }
            }).finally(() => this.setState(newState, () => this.setFileUploader()));
        } else {
            this.setFileUploader();
        }
    }

    getServerConfig = () => {
        const accessToken = Session.getCookie();
        let config = {
            process: {
                url: `${Api.baseUrl}/containers/${Incentive.FILE_CONTAINER_NAME}/upload`,
                headers: {
                    'X-Access-Token': accessToken
                }
            },
            revert: {
                url: this.state.deleteUrl,
                headers: {
                    'X-Access-Token': accessToken
                }
            }
        };

        if (this.isEditMode()) {
            config = Object.assign({
                load: {
                    url: `${Api.baseUrl}/containers/${Incentive.FILE_CONTAINER_NAME}/download/`,
                    headers: {
                        'X-Access-Token': accessToken
                    }
                }
            }, config);
        }

        return config;
    };

    isEditMode = () => this.props.mode === Constants.Form.MODE_EDIT;

    onCancel = () => this.props.history.push(IncentiveForm.INDEX_ROUTE);

    onChangeName = e => this.setState({formValues: Object.assign(this.state.formValues, {name: e.target.value})});

    onChangeDescription = e => this.setState({
        formValues: Object.assign(this.state.formValues, {description: e.target.value})
    });

    onChangePoints = e => this.setState({formValues: Object.assign(this.state.formValues, {points: e.target.value})});

    onSave = () => {
        const constraints = {
            name: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el nombre'
                }
            },
            points: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa la cantidad de puntos'
                },
                numericality: {
                    onlyInteger: true,
                    strict: true,
                    greaterThan: 0,
                    message: 'Ingresa una cantidad de puntos válida'
                }
            },
            photoFilename: {
                presence: {
                    allowEmpty: false,
                    message: 'Sube la foto'
                }
            },
            photoUrl: {
                presence: {
                    allowEmpty: false,
                    message: 'Sube la foto'
                }
            }
        };
        const result = validate(this.state.formValues, constraints, {format: 'flat', fullMessages: false});

        if (isArray(result)) {
            Alert.error(result.join('<br>'));
        } else {
            this.setState({processing: true}, () => {
                const data = clone(this.state.formValues);

                if (!data.description) {
                    delete data.description;
                }

                if (this.isEditMode()) {
                    Incentive.update(this.props.id, data).then(result => {
                        if (result.status) {
                            Alert.info('Incentivo editado');
                        } else {
                            Alert.error('Error. No se pudo editar el incentivo')
                        }
                    }).finally(() => this.setState({processing: false}));
                } else {
                    Incentive.create(data).then(result => {
                        if (result.status) {
                            Alert.info('Incentivo creado');
                            this.props.history.push(IncentiveForm.INDEX_ROUTE);
                        } else {
                            Alert.error('Error. No se pudo crear el incentivo')
                        }
                    }).finally(() => this.setState({processing: false}));
                }
            });
        }
    };

    onUpdateFiles = fileItems => {
        let files = [];

        if (fileItems[0]) {
            files.push(fileItems[0].file);
        }

        this.setState({files: files});
    };

    renderSubmitButton = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <div>
                <Button className="btn-derive" onClick={this.onCancel}>CANCELAR</Button>
                <Button className="btn-derive" onClick={this.onSave}>{this.isEditMode() ? 'GUARDAR' : 'CREAR'}</Button>
            </div>
        );
    };

    setFileUploader = () => {
        const pond = document.querySelector('.filepond--root');

        pond.addEventListener('FilePond:processfile', e => {
            const serverId = JSON.parse(e.detail.file.serverId);
            const file = serverId.result.files.filepond[0];
            const filename = file.name;
            const url = `${Api.baseUrl}/containers/${Incentive.FILE_CONTAINER_NAME}/download/${filename}`;
            const deleteUrl = `${Api.baseUrl}/containers/${Incentive.FILE_CONTAINER_NAME}/files/${filename}`;

            this.setState({
                formValues: Object.assign(this.state.formValues, {photoFilename: filename, photoUrl: url}),
                deleteUrl: deleteUrl
            });
        });

        if (this.isEditMode()) {
            this.pond.addFile(this.state.model.photoFilename, {type: 'local'});
        }
    };

    render() {
        if (this.isEditMode()) {
            if (this.state.loading) {
                return (
                    <Wave color="#D81626" size={50}/>
                );
            }
        }

        return (
            <Form>
                <div className="row flex">
                    <div className="col-sm-10 col-2 pad0">
                        <div className="row flex">
                            <div className="col-sm-12">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="name">
                                            <img alt="" src="/img/svg/icon_tipodecompra.svg" width="20"/>
                                        </Label>
                                        <Input id="name"
                                               name="name"
                                               onChange={this.onChangeName}
                                               placeholder="Nombre *"
                                               type="text"
                                               value={this.state.formValues.name}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="description">
                                            <img alt="" src="/img/svg/icon_tipodecompra.svg" width="20"/>
                                        </Label>
                                        <Input id="description"
                                               name="description"
                                               onChange={this.onChangeDescription}
                                               placeholder="Descripción"
                                               type="text"
                                               value={this.state.formValues.description}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="description">
                                            <img alt="" src="/img/svg/icon_tipodecompra.svg" width="20"/>
                                        </Label>
                                        <Input id="points"
                                               name="points"
                                               onChange={this.onChangePoints}
                                               placeholder="Puntos *"
                                               type="text"
                                               value={this.state.formValues.points}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <FilePond ref={ref => this.pond = ref}
                                                  files={this.state.files}
                                                  allowMultiple={false}
                                                  maxFiles={1}
                                                  onupdatefiles={this.onUpdateFiles}
                                                  server={this.getServerConfig()}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 col-2 pad0">
                        <div className="flex flex-btns">
                            <div>
                                {this.renderSubmitButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default withRouter(IncentiveForm);
