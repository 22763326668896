import {AvFeedback, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import {Squares} from 'react-activity';
import 'react-activity/dist/react-activity.css';
import {Redirect, withRouter} from 'react-router-dom';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import {Button, Label} from 'reactstrap';
import Auth from '../lib/Auth';
import RealTime from '../lib/RealTime';
import User from '../models/User';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processing: false
        };
    }

    /**
     * Submit Login Form
     * @param {*} event 
     * @param {*} errors 
     * @param {*} values 
     * @returns 
     */
    onSubmit = (event, errors, values) => {
        event.preventDefault();

        this.setState({processing: true}, async () => {
            const result = await User.login(values.username, values.password);

            await this.setState({processing: false});

            if (result.status) {
                await this.props.updateRole(result.data.user.role);
                RealTime.setChannels(this.props.pusher, result.data.userId);
                this.props.history.push('/')
            } else {
                this.showAlert();
            }
        });

        return false;
    };

    renderButton = () => {
        if (this.state.processing) {
            return (
                <button className="btnLogin" type="button">
                    <Squares color="#A9D425"/>
                </button>
            );
        }

        return (
            <Button className="btnLogin" type="submit">INICIAR SESIÓN</Button>
        );
    };

    showAlert = () => Alert.error('Acceso no autorizado', {
        position: 'top-right',
        effect: 'slide',
        html: false,
        timeout: 1500
    });

    render() {
        return (
            <AvForm onSubmit={this.onSubmit} width="100%">
                <div className="row mrgTop20">
                    <div className="col-sm-4 offset-sm-4">
                        <div className="pad50_0">
                            <AvGroup className="form-group">
                                <div className="icon-addon addon-lg">
                                    <Label className="glyphicon" for="username">
                                        <img alt="" src="/img/svg/icon_user.svg" width="20"/>
                                    </Label>
                                    <AvInput id="username"
                                             name="username"
                                             placeholder="Usuario"
                                             required
                                             type="text"
                                             value=""/>
                                    <AvFeedback>Ingrese el usuario</AvFeedback>
                                </div>
                            </AvGroup>
                            <AvGroup className="form-group">
                                <div className="icon-addon addon-lg">
                                    <Label className="glyphicon" for="password">
                                        <img alt="" src="/img/svg/icon_padlock.svg" width="20"/>
                                    </Label>
                                    <AvInput id="password"
                                             name="password"
                                             placeholder="Contraseña"
                                             required
                                             type="password"
                                             value=""/>
                                    <AvFeedback>Ingrese la contraseña</AvFeedback>
                                </div>
                            </AvGroup>
                        </div>
                        {this.renderButton()}
                    </div>
                </div>
            </AvForm>
        );
    }
}

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {from} = this.props.location.state || {from: {pathname: '/'}};

        if (Auth.isAuthenticated()) {
            return (
                 <Redirect to={from}/>
            );
        }

        return (
            <div>
                <div className="flex-login">
                    <div className="container">
                        <div className="d-flex justify-content-center">
                            <div>
                                <img alt="" src="/img/svg/logo.svg" width="399"/>
                            </div>
                        </div>
                        <LoginForm history={this.props.history} pusher={this.props.pusher} updateRole={this.props.updateRole}/>
                    </div>
                </div>
                <Alert stack={{limit: 4}}/>
            </div>
        );
    }
}

export default withRouter(Login);
