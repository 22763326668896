import {parallel, waterfall} from 'async';
import {Wave} from 'better-react-spinkit';
import {clone, isArray, isEmpty, isUndefined} from 'lodash';
import React from 'react';
import {withRouter} from 'react-router-dom';
import Select from 'react-select';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import validate from 'validate.js';
import Alert from '../../lib/Alert';
import Constants from '../../lib/Constants';
import Brand from '../../models/Brand';
import Dealership from '../../models/Dealership';
import Department from '../../models/Department';
import District from '../../models/District';
import Province from '../../models/Province';
import Store from '../../models/Store';

class StoreForm extends React.Component {
    static INDEX_ROUTE = '/stores';
    static defaultDealerships = [
        {
            id: '',
            name: 'Concesionario *'
        }
    ];
    static defaultBrands = [
        {
            value: '',
            label: 'Selecciona...',
            disabled: true
        }
    ];
    static defaultDepartments = [
        {
            id: '',
            name: 'Departamento *'
        }
    ];
    static defaultProvinces = [
        {
            id: '',
            name: 'Provincia *'
        }
    ];
    static defaultDistricts = [
        {
            id: '',
            name: 'Distrito *'
        }
    ];

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            processing: false,
            dealerships: StoreForm.defaultDealerships,
            brands: StoreForm.defaultBrands,
            selectedBrands: [],
            departments: StoreForm.defaultDepartments,
            provinces: StoreForm.defaultProvinces,
            districts: StoreForm.defaultDistricts,
            formValues: {
                dealershipId: '',
                brandIds: [],
                departmentId: '',
                provinceId: '',
                districtId: '',
                name: '',
                address: '',
                enabled: ''
            }
        };
    }

    componentDidMount() {
        let newState = {
            loading: false,
            brands: [],
            selectedBrands: []
        };
        const tasks = {
            dealerships: callback => {
                Dealership.find().then(result => {
                    if (result.status) {
                        newState.dealerships = StoreForm.defaultDealerships.concat(result.data);
                    }

                    callback(null, result.status);
                }).catch(e => callback(e, false));
            },
            brands: callback => {
                Brand.find().then(result => {
                    if (result.status) {
                        newState.brands = result.data;
                    }

                    callback(null, result.status);
                }).catch(e => callback(e, false));
            },
            departments: callback => {
                Department.find().then(result => {
                    if (result.status) {
                        newState.departments = StoreForm.defaultDepartments.concat(result.data);
                    }

                    callback(null, result.status);
                }).catch(e => callback(e, false));
            }
        };

        if (this.isEditMode()) {
            tasks.model = callback => {
                const params = `filter[include]=dealership&filter[include]=brands&filter[include]=district`;

                Store.findById(this.props.id, params).then(result => {
                    if (result.status) {
                        const model = result.data;
                        newState.model = model;
                        newState.formValues = Object.assign(this.state.formValues, {
                            dealershipId: model.dealershipId,
                            brandIds: model.brandIds,
                            departmentId: '',
                            provinceId: '',
                            districtId: model.districtId || '',
                            name: model.name,
                            address: model.address,
                            enabled: model.enabled
                        });
                        const subtasks = [
                            subcallback => {
                                const params = `filter[where][name]=${model.district.province.name}`;

                                Province.find(params).then(result => {
                                    if (result.status) {
                                        const province = result.data[0];
                                        newState.formValues.provinceId = province.id;

                                        subcallback(null, province);
                                    } else {
                                        subcallback(null, false);
                                    }
                                }).catch(e => subcallback(e, false));
                            },
                            (province, subcallback) => {
                                if (province) {
                                    const params = `filter[where][name]=${province.department.name}`;

                                    Department.find(params).then(result => {
                                        if (result.status) {
                                            const department = result.data[0];
                                            newState.formValues.departmentId = department.id;
                                            const paramsX = `filter[where][department.name]=${department.name}`;

                                            Province.find(paramsX).then(resultX => {
                                                if (resultX.status) {
                                                    newState.provinces = StoreForm.defaultProvinces.concat(resultX.data);
                                                    const paramsY = `filter[where][province.name]=${province.name}`;

                                                    District.find(paramsY).then(resultY => {
                                                        if (resultY.status) {
                                                            newState.districts = StoreForm.defaultDistricts.concat(resultY.data);
                                                        }

                                                        subcallback(null, resultY.status);
                                                    }).catch(e => subcallback(e, false));
                                                } else {
                                                    subcallback(null, false);
                                                }
                                            }).catch(e => subcallback(e, false));

                                        } else {
                                            subcallback(null, false);
                                        }
                                    }).catch(e => subcallback(e, false));
                                } else {
                                    subcallback(null, false);
                                }
                            }
                        ];

                        waterfall(subtasks, (err, result) => {
                            if (err) {
                                callback(err, false);
                            } else {
                                callback(null, result);
                            }
                        });
                    } else {
                        callback(null, false);
                    }
                }).catch(e => callback(e, false));
            };
        }

        parallel(tasks, (err, result) => {
            let errorMessage = 'Lo sentimos, ocurrió un error';

            if (err) {
                Alert.error(errorMessage);
            } else {
                const everythingWasOk = Object.values(result).every(x => x === true);
                let proceed = true;

                if (everythingWasOk) {
                    if (this.isEditMode()) {
                        if (result.model) {
                            let selectedBrands = [];

                            newState.formValues.brandIds.forEach(brandId => {
                                const filteredBrandIds = newState.brands.filter(x => x.id === brandId);

                                if (!isEmpty(filteredBrandIds)) {
                                    const brand = filteredBrandIds[0];

                                    selectedBrands.push({value: brand.id, label: brand.name});
                                }
                            });

                            newState.selectedBrands = selectedBrands;
                        } else {
                            proceed = false;

                            Alert.error('Tienda inválida');
                            this.props.history.push(StoreForm.INDEX_ROUTE);
                        }
                    }

                    if (proceed) {
                        this.setState(newState);
                    }
                } else {
                    Alert.error(errorMessage);
                }
            }
        });
    }

    getBrandOptions = () => StoreForm.defaultBrands.concat(this.state.brands.map(x => {
        return {value: x.id, label: x.name};
    }));

    isEditMode = () => this.props.mode === Constants.Form.MODE_EDIT;

    onCancel = () => this.props.history.push(StoreForm.INDEX_ROUTE);

    onChangeBrandIds = options => {
        let newState = {
            selectedBrands: options
        };
        const brandIds = options.map(x => x.value).filter(x => !isUndefined(x));

        if (!isEmpty(brandIds)) {
            newState.formValues = Object.assign(this.state.formValues, {brandIds: brandIds});
        }

        this.setState(newState);
    };

    onChangeDealershipId = e => {
        this.setState({formValues: Object.assign(this.state.formValues, {dealershipId: e.target.value})});
    };

    onChangeDepartmentId = e => {
        const departmentId = e.target.value;
        const departments = (this.state.departments.filter(x => x.id === departmentId));
        const departmentName = !isEmpty(departments) ? departments[0].name : '';
        const newState = {
            processing: true,
            formValues: Object.assign(this.state.formValues, {departmentId: departmentId})
        };

        this.setState(newState, () => {
            const params = `filter[where][department.name]=${departmentName}`;

            Province.find(params).then(result => {
                this.setState({
                    processing: false,
                    provinces: StoreForm.defaultProvinces.concat(result.data),
                    districts: StoreForm.defaultDistricts
                });
            });
        });
    };

    onChangeProvinceId = e => {
        const provinceId = e.target.value;
        const provinces = (this.state.provinces.filter(x => x.id === provinceId));
        const provinceName = !isEmpty(provinces) ? provinces[0].name : '';
        const newState = {
            processing: true,
            formValues: Object.assign(this.state.formValues, {provinceId: provinceId})
        };

        this.setState(newState, () => {
            const params = `filter[where][province.name]=${provinceName}`;

            District.find(params).then(result => {
                this.setState({
                    processing: false,
                    districts: StoreForm.defaultDistricts.concat(result.data)
                });
            });
        });
    };

    onChangeDistrictId = e => {
        this.setState({formValues: Object.assign(this.state.formValues, {districtId: e.target.value})});
    };

    onChangeName = e => this.setState({
        formValues: Object.assign(this.state.formValues, {name: e.target.value})
    });

    onChangeAddress = e => this.setState({
        formValues: Object.assign(this.state.formValues, {address: e.target.value})
    });

    onChangeEnabled = e => this.setState({
        formValues: Object.assign(this.state.formValues, {enabled: e.target.value})
    });

    onSave = () => {
        const constraints = {
            dealershipId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el concesionario'
                }
            },
            brandIds: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona una o varias marcas'
                }
            },
            departmentId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el departamento'
                }
            },
            provinceId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona la provincia'
                }
            },
            districtId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el distrito'
                }
            },
            name: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el nombre'
                }
            },
            enabled: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el estado'
                }
            }
        };
        const result = validate(this.state.formValues, constraints, {format: 'flat', fullMessages: false});

        if (isArray(result)) {
            Alert.error(result.join('<br>'));
        } else {
            this.setState({processing: true}, () => {
                let initialData = {
                    dealershipId: '',
                    brandIds: [],
                    departmentId: '',
                    provinceId: '',
                    districtId: '',
                    name: '',
                    address: '',
                    enabled: ''
                };

                if (!this.isEditMode()) {
                    initialData.deleted = false;
                }

                let data = Object.assign(initialData, clone(this.state.formValues));
                const newState = {
                    processing: false
                };

                delete data.departmentId;
                delete data.provinceId;

                if (!data.address) {
                    delete data.address;
                }

                if (this.isEditMode()) {
                    Store.update(this.props.id, data).then(result => {
                        if (result.status) {
                            Alert.info('Tienda editada');
                        } else {
                            Alert.error('Error. No se pudo editar la tienda')
                        }
                    }).finally(() => this.setState(newState));
                } else {
                    Store.create(data).then(result => {
                        if (result.status) {
                            Alert.info('Tienda creada');
                            this.props.history.push(StoreForm.INDEX_ROUTE);
                        } else {
                            Alert.error('Error. No se pudo crear la tienda')
                        }
                    }).finally(() => this.setState(newState));
                }
            });
        }
    };

    renderSubmitButton = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <div>
                <Button className="btn-derive" onClick={this.onCancel}>CANCELAR</Button>
                <Button className="btn-derive" onClick={this.onSave}>{this.isEditMode() ? 'GUARDAR' : 'CREAR'}</Button>
            </div>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <Form>
                <div className="row flex">
                    <div className="col-sm-10 col-2 pad0">
                        <div className="row flex">
                            <div className="col-sm-6">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="dealershipId">
                                            <img alt="" src="/img/svg/icon_tienda.svg" width="20"/>
                                        </Label>
                                        <Input id="dealershipId"
                                               name="dealershipId"
                                               onChange={this.onChangeDealershipId}
                                               type="select"
                                               value={this.state.formValues.dealershipId}
                                        >
                                            {this.state.dealerships.map(dealership =>
                                                <option key={`dealership-${dealership.id}`}
                                                        value={dealership.id}>{dealership.name}
                                                </option>
                                            )}
                                        </Input>
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="brandIds">
                                            <img alt="" src="/img/svg/icon_tienda.svg" width="20"/>
                                        </Label>
                                        <Select id="brandIds"
                                                name="brandIds"
                                                isMulti={true}
                                                isOptionDisabled={option => option.disabled}
                                                onChange={this.onChangeBrandIds}
                                                options={this.getBrandOptions()}
                                                placeholder="Marcas *"
                                                value={this.state.selectedBrands}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="departmentId">
                                            <img alt="" src="/img/svg/icon_departamentos.svg" width="20"/>
                                        </Label>
                                        <Input id="departmentId"
                                               name="departmentId"
                                               onChange={this.onChangeDepartmentId}
                                               type="select"
                                               value={this.state.formValues.departmentId}
                                        >
                                            {this.state.departments.map(department =>
                                                <option key={`department-${department.id}`}
                                                        value={department.id}>{department.name}
                                                </option>
                                            )}
                                        </Input>
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="provinceId">
                                            <img alt="" src="/img/svg/icon_departamentos.svg" width="20"/>
                                        </Label>
                                        <Input id="provinceId"
                                               name="provinceId"
                                               onChange={this.onChangeProvinceId}
                                               type="select"
                                               value={this.state.formValues.provinceId}
                                        >
                                            {this.state.provinces.map(province =>
                                                <option key={`province-${province.id}`}
                                                        value={province.id}>{province.name}
                                                </option>
                                            )}
                                        </Input>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-6">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="districtId">
                                            <img alt="" src="/img/svg/icon_departamentos.svg" width="20"/>
                                        </Label>
                                        <Input id="districtId"
                                               name="districtId"
                                               onChange={this.onChangeDistrictId}
                                               type="select"
                                               value={this.state.formValues.districtId}
                                        >
                                            {this.state.districts.map(district =>
                                                <option key={`district-${district.id}`}
                                                        value={district.id}>{district.name}
                                                </option>
                                            )}
                                        </Input>
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="name">
                                            <img alt="" src="/img/svg/icon_tienda.svg" width="20"/>
                                        </Label>
                                        <Input id="name"
                                               name="name"
                                               onChange={this.onChangeName}
                                               placeholder="Nombre *"
                                               type="text"
                                               value={this.state.formValues.name}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="address">
                                            <img alt="" src="/img/svg/icon_tienda.svg" width="20"/>
                                        </Label>
                                        <Input id="address"
                                               name="address"
                                               onChange={this.onChangeAddress}
                                               placeholder="Dirección"
                                               type="text"
                                               value={this.state.formValues.address}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="enabled">
                                            <img alt="" src="/img/svg/icon_tipodecompra.svg" width="20"/>
                                        </Label>
                                        <Input id="enabled"
                                               name="enabled"
                                               onChange={this.onChangeEnabled}
                                               type="select"
                                               value={this.state.formValues.enabled}
                                        >
                                            <option value="">Selecciona...</option>
                                            <option value="true">Habilitado</option>
                                            <option value="false">Deshabilitado</option>
                                        </Input>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 col-2 pad0">
                        <div className="flex flex-btns">
                            {this.renderSubmitButton()}
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default withRouter(StoreForm);
