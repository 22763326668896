import Api from '../lib/models/Api';
import Session from '../lib/Session';

class User extends Api {
    static endpoint = '/users';

    static login = async (username, password) => {

        // Init Result object
        let result = {
            status: false,
            message: 'Error',
            data: null
        };

        // Init login try
        try {

            // Route to make request
            const to = '/admin-login?include=user';

            // Data
            const data = {
                username: username,
                password: password
            };
            
            // Send http request
            const response = await User.post(data, to);

            // If success set session
            if (response.statusCode === 200) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;

                Session.setCookie(result.data.id);
                Session.setUser(result.data.user);
            }
        } catch (e) {
            const response = e.response;

            if (response.status === 400) {
                result.message = 'Ingrese el usuario y/o contraseña';
            } else if (response.status === 401) {
                result.message = 'Usuario y/o contraseña incorrecta';
            } else {
                result.message = e.message;
            }
        }

        return result;
    };

    static logout = () => {

    };

    static me = async () => {
        let result = {
            status: false,
            message: 'Error',
            data: null
        };

        try {
            const to = '/me';
            const response = await User.get({}, to);

            if (response.statusCode === 200) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;
            }
        } catch (e) {

        }

        return result;
    };
}

export default User;
