import {Wave} from 'better-react-spinkit';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {isEmpty} from 'lodash';
import React from 'react';
import Constants from '../../lib/Constants';
import Report from '../../models/Report';
import Utils from '../../lib/Utils';

class LeadsScheduled extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: [],
            options: {}
        };
    }

    componentDidMount() {
        this.load();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({loading: true}, () => this.load());
    }

    load = () => {
        let to = `/${Constants.Report.LEADS_SCHEDULED}`;
        const params = Utils.getReportParams(this.props.filter);

        if (!isEmpty(params)) {
            to += '?' + params.join('&');
        }

        Report.get({}, to).then(result => {
            const data = result.data;
            const options = {
                chart: {
                    backgroundColor: '#CED2d5',
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    shadow: {
                        color: 'rgba(0, 0, 0, 0.4)',
                        offsetX: 1,
                        offsetY: 1,
                        opacity: '0.3',
                        width: 5
                    },
                    width: 420,
                    height: 350,
                    animation: false,
                    type: 'pie'
                },
                title: {
                    text: '',
                    y: 30
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
                },
                legend: {
                    align: 'right',
                    verticalAlign: 'middle',
                    layout: 'vertical'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        colors: ['#E40012','#5E5E5E'],
                        dataLabels: {
                            enabled: true,
                            format: '{point.percentage:.1f}%',
                            distance: -50,
                            filter: {
                                property: 'percentage',
                                operator: '>',
                                value: 4
                            }
                        },
                        showInLegend: true
                    }
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Porcentaje',
                    colorByPoint: true,
                    data: data.rows
                }]
            };

            this.setState({loading: false, data: data, options: options})
        });
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className={"boxGraphics"}>
                <div>
                    <h4>Visitas agendadas</h4>
                    <HighchartsReact highcharts={Highcharts} options={this.state.options}/>
                </div>
            </div>
        );
    }
}

export default LeadsScheduled;
