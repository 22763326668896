import Api from '../lib/models/Api';

class Lead extends Api {
    static endpoint = '/leads';

    static import = async (file) => {
        let result = {
            status: false,
            message: 'Error',
            data: null
        };

        try {
            const to = '/imports';

            const data = new FormData();
            data.append('file', file);
            const response = await Lead.post(data, to);
            if (response.statusCode === 200) {
                result.status = true;
                result.message = 'Ok';
                result.data = response.data;
            }
        } catch (e) {
            // const response = e.response;
            result.message = e.message;
        }

        return result;
    };
}

export default Lead;
