import React from 'react';

class NotFound extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Página no encontrada</h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
