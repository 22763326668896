import {Wave} from 'better-react-spinkit';
import React from 'react';
import roundTo from 'round-to';
import Constants from '../../lib/Constants';
import Report from '../../models/Report';

class RankingPerDealership extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            data: []
        };
    }

    componentDidMount() {
        const to = `/${Constants.Report.RANKING_PER_DEALERSHIP}`;

        Report.get({}, to).then(result => {
            const data = result.data;

            this.setState({loading: false, data: data})
        });
    }

    renderDiamonds = row => {
        const rating = roundTo(row.rating, 0);
        let markers = [1, 2, 3, 4, 5];

        return markers.map(marker =>
            <li key={`diamonds-${row.id}-${marker}`}>
                <img alt="" src={marker <= rating ? '/img/diamonds-red.png' : '/img/diamonds-gray.png'} width="30px"/>
            </li>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <div className={"boxGraphics"}>
                <div>
                    <h4>Ranking por concesionario</h4>
                    <table className="calification-content">
                        <tbody>
                        {this.state.data.map(row => (
                            <tr key={`row-${row.id}`}>
                                <td>
                                    <h6>{row.dealershipName}</h6>
                                </td>
                                <td>
                                    <ul className="calification">
                                        {this.renderDiamonds(row)}
                                    </ul>
                                </td>
                                <td>
                                    <span>{roundTo(row.rating, 0) / 5 * 100}%</span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default RankingPerDealership;
