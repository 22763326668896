import {FilteringState, IntegratedFiltering} from '@devexpress/dx-react-grid';
import {Grid, Table, TableColumnResizing, TableFilterRow, TableHeaderRow} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import {Wave} from 'better-react-spinkit';
import {in_array as inArray} from 'locutus/php/array';
import {isArray, isEmpty, isNil} from 'lodash';
import React from 'react';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {DebounceInput} from 'react-debounce-input';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {IconContext} from 'react-icons';
import {FaEdit, FaTrash} from 'react-icons/fa';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Link} from 'react-router-dom';
import {FormGroup, Label} from 'reactstrap';
import Api from '../../../lib/models/Api';
import Alert from '../../../lib/Alert';
import Constants from '../../../lib/Constants';
import Session from '../../../lib/Session';
import Utils from '../../../lib/Utils';
import Store from '../../../models/Store';
import User from '../../../models/User';

class DeleteButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            processing: false
        };
    }

    delete = () => {
        this.setState({processing: true}, () => {
            const data = {
                enabled: false,
                deleted: true
            };
            const newState = {
                processing: false
            };
            const errorMessage = 'Error. No se pudo borrar al usuario';

            User.update(this.props.row.id, data).then(result => {
                if (result.status) {
                    this.props.onDelete(this.props.row);
                    Alert.info('Usuario borrado');
                } else {
                    Alert.error(errorMessage);
                }
            }).catch(e => {
                console.log(e);
                Alert.error(errorMessage);
            }).finally(() => this.setState(newState));
        });
    };

    onClick = e => {
        e.preventDefault();

        const fullname = [this.props.row.firstName, this.props.row.lastName].join(' ');

        confirmAlert({
            title: `Borrar usuario ${fullname}`,
            message: '¿Estás segur@?',
            buttons: [
                {
                    label: 'Sí',
                    onClick: () => this.delete()
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    };

    render() {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <a className="pl-1" href="/" onClick={this.onClick}>
                <FaTrash/>
            </a>
        );
    }
}

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            role: '',
            prevParams: '',
            columns: [
                {
                    name: 'actions',
                    title: 'Acción',
                    getCellValue: row => (
                        <IconContext.Provider value={{color: '#E30012'}}>
                            <a className="pl-1" href={`/users/${row.id}/edit`}>
                                <FaEdit/>
                            </a>
                            <DeleteButton row={row} onDelete={this.onDelete}/>
                        </IconContext.Provider>
                    )
                },
                {
                    name: 'username',
                    title: 'Nombre de usuario'
                },
                {
                    name: 'email',
                    title: 'Correo electrónico'
                },
                {
                    name: 'firstName',
                    title: 'Nombres'
                },
                {
                    name: 'lastName',
                    title: 'Apellidos'
                },
                {
                    name: 'storeName',
                    title: 'Tienda',
                    getCellValue: row => {
                        if (!isNil(row.store)) {
                            const user = Session.getUser();

                            return inArray(user.role, [Constants.Role.ROOT, Constants.Role.ADMIN]) ? <Link to={`/stores/${row.store.id}/edit`}>{row.store.name}</Link> : row.store.name
                        }

                        return '';
                    }
                },
                {
                    name: 'brandNames',
                    title: 'Marcas',
                    getCellValue: row => !isNil(row.brands) && !isEmpty(row.brands) ? row.brands.map(x => x.name).join(', ') : ''
                },
                {
                    name: 'roleName',
                    title: 'Cargo',
                    getCellValue: row => Utils.getUserRoleName(row.role)
                },
                {
                    name: 'rating',
                    title: 'Diamantes',
                    getCellValue: row => row.role === Constants.Role.SALES && !isNil(row.rating) ? row.rating : ''
                },
                {
                    name: 'indicators',
                    title: 'Indicadores',
                    getCellValue: row => {
                        if (row.role === Constants.Role.SALES && !isEmpty(row.indicators) && isArray(row.indicators)) {
                            return (
                                <div>
                                    {row.indicators.map((indicator, i) => <p key={`indicator-${row.id}-${i}`}>{indicator.name}: {indicator.value}</p>)}
                                </div>
                            );
                        }

                        return '';
                    }
                },
                {
                    name: 'statusName',
                    title: 'Estado',
                    getCellValue: row => row.enabled ? 'Habilitado': 'Deshabilitado'
                }
            ],
            columnExtensions: [
                {columnName: 'actions', filteringEnabled: false}
            ],
            defaultColumnWidths: [
                {columnName: 'actions', width: 180},
                {columnName: 'username', width: 180},
                {columnName: 'email', width: 240},
                {columnName: 'firstName', width: 180},
                {columnName: 'lastName', width: 180},
                {columnName: 'storeName', width: 360},
                {columnName: 'brandNames', width: 240},
                {columnName: 'roleName', width: 240},
                {columnName: 'rating', width: 120},
                {columnName: 'indicators', width: 360},
                {columnName: 'statusName', width: 180}
            ],
            rows: [],
            searchText: ''
        };
    }

    componentDidMount() {
        const user = Session.getUser();

        if (user.role === Constants.Role.SALES_MANAGER) {
            const params = `filter[where][salesManagerId]=${user.id}`;
            const newState = {
                loading: false
            };

            Store.find(params).then(result => {
                if (result.status) {
                    let stores = result.data;

                    if (isEmpty(stores)) {
                        // Use a fake store ID
                        stores = [{id: 'abc'}];
                    }

                    newState.prevParams = stores.map((store, i) => `filter[where][storeId][inq][${i}]=${store.id}`).filter(x => Utils.isUsable(x)).join('&');

                    this.setState(newState, () => this.load());
                } else {
                    this.notifyAboutFailedLoad();
                }
            }).catch(e => {
                console.log(e);
                this.notifyAboutFailedLoad();
            });
        } else {
            this.load();
        }
    }

    getRoles = () => {
        return [
            Constants.Role.ADMIN,
            Constants.Role.COORDINATOR,
            Constants.Role.MAIN_MANAGER,
            Constants.Role.BUSINESS_MANAGER,
            Constants.Role.MARKETING_MANAGER,
            Constants.Role.BUSINESS_DELEGATE,
            Constants.Role.DEALERSHIP_MANAGER,
            Constants.Role.SALES_MANAGER,
            Constants.Role.SALES,
            Constants.Role.CALL_CENTER_SUPERVISOR,
            Constants.Role.CALL_CENTER,
            Constants.Role.BACKOFFICE
        ];
    };

    getRoleOptions = () => this.getRoles().map(role => {
        return {value: role, label: Utils.getUserRoleName(role)};
    });

    getRoleParams = () => {
        if (Utils.isUsable(this.state.role)) {
            return `filter[where][role]=${this.state.role}`;
        }

        return this.getRoles().map((role, i) => `filter[where][role][inq][${i}]=${role}`).filter(x => Utils.isUsable(x)).join('&');
    };

    load = () => {
        this.setState({loading: true}, () => {
            const params = [
                this.state.prevParams,
                this.getRoleParams(),
                `filter[where][deleted]=false&filter[include]=store&filter[include]=brands&filter[order][0]=firstName ASC&filter[order][1]=lastName ASC`
            ].filter(x => Utils.isUsable(x)).join('&');

            User.find(params).then(result => {
                if (result.status) {
                    this.setState({loading: false, rows: result.data});
                }
            });
        });
    };

    notifyAboutFailedLoad = () => Alert.error('Lo sentimos, ocurrió un error');

    onDelete = row => this.setState({rows: this.state.rows.filter(x => x.id !== row.id)});

    onDownload = e => {
        e.preventDefault();

        const accessToken = Session.getCookie();
        const url = `${Api.baseUrl}${User.endpoint}/exports?access_token=${accessToken}`;

        window.open(url, '_blank');
    };

    onChangeRole = e => this.setState({role: e.value}, () => this.load());

    onChangeSearchText = e => {
        this.setState({loading: true, searchText: e.target.value}, () => {
            if (this.state.searchText) {
                const newState = {
                    loading: false
                };
                const param = [
                    this.state.prevParams,
                    this.getRoleParams(),
                    `filter[where][deleted]=false&filter[where][$text][search]=${this.state.searchText}&filter[include]=store&filter[order][0]=firstName ASC&filter[order][1]=lastName ASC`
                ].filter(x => Utils.isUsable(x)).join('&');

                User.find(param).then(result => {
                    if (result.status) {
                        newState.rows = result.data;
                    }
                }).finally(() => this.setState(newState));
            } else {
                this.load()
            }
        })
    };

    renderTable = () => {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <Grid rows={this.state.rows} columns={this.state.columns}>
                <FilteringState columnExtensions={this.state.columnExtensions}/>
                <IntegratedFiltering/>
                <Table/>
                <TableColumnResizing defaultColumnWidths={this.state.defaultColumnWidths}/>
                <TableHeaderRow/>
                <TableFilterRow messages={{filterPlaceholder: 'Filtro...'}}/>
            </Grid>
        );
    };

    render() {
        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">USUARIOS</h2>
                                <div className="subline"/>
                            </div>
                            <div>
                                <Link to="/users/create">
                                    <img alt="" src="/img/svg/icon_mas_rojo.svg" width="25"/>
                                </Link>
                            </div>
                            <div className="InputSearch col-md-4">
                                <FormGroup className="form-group">
                                    <Label className="glyphicon pr-2 mb-0" for="role">Cargo</Label>
                                    <Dropdown id="role"
                                              name="role"
                                              className={"form-control input-select-report"}
                                              onChange={this.onChangeRole}
                                              options={this.getRoleOptions()}
                                              placeholder="Selecciona..."
                                              value={this.state.role}
                                    />
                                </FormGroup>
                            </div>
                            <div className="InputSearch col-md-4">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="searchText">
                                            <img alt="" src="/img/svg/icon_buscar.svg" width="20"/>
                                        </Label>
                                        <DebounceInput id="searchText"
                                                       name="searchText"
                                                       className="form-control"
                                                       debounceTimeout={300}
                                                       minLength={2}
                                                       onChange={this.onChangeSearchText}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {this.renderTable()}
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="d-flex justify-content-end align-items-center">
                                    <div>
                                        <a href="/" onClick={this.onDownload}>
                                            <img alt="Exportar" className="icon_excel mr-2" src="/img/svg/icon_excel.svg" width="30"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Index;
