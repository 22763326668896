import {parallel, series} from 'async';
import {Wave} from 'better-react-spinkit';
import {clone, isArray, isEmpty, isNil} from 'lodash';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import validate from 'validate.js';
import Alert from '../../../lib/Alert';
import Constants from '../../../lib/Constants';
import User from '../../../models/User';

class CallCenterUserForm extends React.Component {
    static INDEX_ROUTE = '/users';

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            model: {},
            formValues: {
                username: '',
                password: '',
                email: '',
                firstName: '',
                lastName: '',
                idDocNumber: '',
                campaign: '',
                enabled: ''
            },
            originalFormValues: {},
            processing: false
        };
    }

    componentDidMount() {
        let newState = {
            loading: false
        };
        let tasks = {};

        if (this.isEditMode()) {
            tasks.model = callback => {
                User.findById(this.props.id).then(result => {
                    if (result.status) {
                        const model = result.data;
                        newState.model = model;
                        newState.formValues = Object.assign(this.state.formValues, {
                            username: model.username,
                            email: model.email,
                            firstName: model.firstName,
                            lastName: model.lastName,
                            idDocNumber: model.idDocNumber,
                            campaign: model.campaign,
                            enabled: model.enabled
                        });
                    }

                    callback(null, result.status);
                }).catch(() => callback(null, false));
            };
        } else {
            newState.formValues = clone(this.state.formValues);
            tasks.dummy = callback => callback(null, true);
        }

        parallel(tasks, (err, result) => {
            if (this.isEditMode()) {
                if (!result.model) {
                    Alert.error('Usuario inválido');
                    this.props.history.push(CallCenterUserForm.INDEX_ROUTE);
                }
            }

            newState.originalFormValues = clone(newState.formValues);

            this.setState(newState);
        });
    }

    isEditMode = () => this.props.mode === Constants.Form.MODE_EDIT;

    onCancel = () => this.props.history.push(CallCenterUserForm.INDEX_ROUTE);

    onChangeUsername = e => this.setState({
        formValues: Object.assign(this.state.formValues, {username: e.target.value})
    });

    onChangeEmail = e => this.setState({
        formValues: Object.assign(this.state.formValues, {email: e.target.value})
    });

    onChangePassword = e => this.setState({
        formValues: Object.assign(this.state.formValues, {password: e.target.value})
    });

    onChangeFirstName = e => this.setState({
        formValues: Object.assign(this.state.formValues, {firstName: e.target.value})
    });

    onChangeLastName = e => this.setState({
        formValues: Object.assign(this.state.formValues, {lastName: e.target.value})
    });

    onChangeIdDocNumber = e => this.setState({
        formValues: Object.assign(this.state.formValues, {idDocNumber: e.target.value})
    });

    onChangeCampaign = e => this.setState({
        formValues: Object.assign(this.state.formValues, {campaign: e.target.value})
    });

    onChangeEnabled = e => this.setState({
        formValues: Object.assign(this.state.formValues, {enabled: e.target.value === 'true'})
    });

    onSave = () => {
        const constraints = {
            username: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el usuario'
                }
            },
            email: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el correo electrónico'
                },
                email: {
                    message: 'El correo electrónico es inválido'
                }
            },
            firstName: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa los nombres'
                }
            },
            lastName: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa los apellidos'
                }
            },
            idDocNumber: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el número de DNI'
                }
            },
            enabled: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el estado'
                }
            }
        };

        if (!this.isEditMode()) {
            constraints.password = {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa la contraseña'
                },
                length: {
                    minimum: 8,
                    message: 'La contraseña debe tener 8 caracteres como mínimo'
                }
            };
        }

        const result = validate(this.state.formValues, constraints, {format: 'flat', fullMessages: false});

        if (isArray(result)) {
            Alert.error(result.join('<br>'));
        } else {
            this.setState({processing: true}, () => {
                let initialData = {
                    username: '',
                    password: '',
                    email: '',
                    role: this.props.role,
                    firstName: '',
                    lastName: '',
                    idDocNumber: '',
                    campaign: '',
                    enabled: true
                };

                if (!this.isEditMode()) {
                    initialData.deleted = false;
                }

                let data = Object.assign(initialData, clone(this.state.formValues));
                let newState = {
                    processing: false
                };
                const errorMessages = {
                    username: {
                        error: 'Lo sentimos, ocurrió un error validando el usuario',
                        duplicated: 'El usuario ya está en uso, por favor ingrese otro'
                    },
                    email: {
                        error: 'Lo sentimos, ocurrió un error validando el correo electrónico',
                        duplicated: 'El correo electrónico ya está en uso, por favor ingrese otro'
                    },
                    idDocNumber: {
                        error: 'Lo sentimos, ocurrió un error validando el número de DNI',
                        duplicated: 'El número de DNI ya está en uso, por favor ingrese otro'
                    }
                };
                let tasks = {
                    username: callback => {
                        const params = `filter[where][username]=${this.state.formValues.username}&filter[where][enabled]=true&filter[where][deleted]=false`;

                        User.find(params).then(result => {
                            if (result.status) {
                                if (!isEmpty(result.data)) {
                                    Alert.error(errorMessages.username.duplicated);
                                }

                                callback(null, {isOk: isEmpty(result.data)});
                            } else {
                                Alert.error(errorMessages.username.error);
                                callback(null, {isOk: false});
                            }
                        }).catch(e => {
                            console.log(e);
                            Alert.error(errorMessages.username.error);
                            callback(e);
                        });
                    },
                    email: callback => {
                        const params = `filter[where][email]=${this.state.formValues.email}&filter[where][enabled]=true&filter[where][deleted]=false`;

                        User.find(params).then(result => {
                            if (result.status) {
                                if (!isEmpty(result.data)) {
                                    Alert.error(errorMessages.email.duplicated);
                                }

                                callback(null, {isOk: isEmpty(result.data)});
                            } else {
                                Alert.error(errorMessages.email.error);
                                callback(null, {isOk: false});
                            }
                        }).catch(e => {
                            console.log(e);
                            Alert.error(errorMessages.email.error);
                            callback(e);
                        });
                    },
                    idDocNumber: callback => {
                        const params = `filter[where][idDocNumber]=${this.state.formValues.idDocNumber}`;

                        User.find(params).then(result => {
                            if (result.status) {
                                if (!isEmpty(result.data)) {
                                    Alert.error(errorMessages.idDocNumber.duplicated);
                                }

                                callback(null, {isOk: isEmpty(result.data)});
                            } else {
                                Alert.error(errorMessages.idDocNumber.error);
                                callback(null, {isOk: false});
                            }
                        }).catch(e => {
                            console.log(e);
                            Alert.error(errorMessages.idDocNumber.error);
                            callback(e);
                        });
                    }
                };

                if (this.isEditMode()) {
                    if (this.state.formValues.username === this.state.originalFormValues.username) {
                        delete data.username;
                        delete tasks.username;
                    }

                    if (this.state.formValues.email === this.state.originalFormValues.email) {
                        delete data.email;
                        delete tasks.email;
                    }

                    if (this.state.formValues.idDocNumber === this.state.originalFormValues.idDocNumber) {
                        delete data.idDocNumber;
                        delete tasks.idDocNumber;
                    }
                }

                if (isEmpty(tasks)) {
                    tasks.dummy = callback => callback(null, true);
                }

                series(tasks, (err, result) => {
                    if (err) {
                        console.log(err);
                        Alert.error('Error');
                    } else {
                        let values = [];

                        if (!isNil(result.username)) {
                            values.push(result.username.isOk);
                        }

                        if (!isNil(result.email)) {
                            values.push(result.email.isOk);
                        }

                        if (!isNil(result.idDocNumber)) {
                            values.push(result.idDocNumber.isOk);
                        }

                        const proceed = isEmpty(values) || values.every(x => x === true);

                        if (proceed) {
                            // Process user
                            if (this.isEditMode()) {
                                if (!data.password) {
                                    delete data.password;
                                }

                                User.update(this.props.id, data).then(result => {
                                    if (result.status) {
                                        newState.originalFormValues = clone(this.state.formValues);

                                        Alert.info('Usuario editado');
                                    } else {
                                        Alert.error('Error. No se pudo editar el usuario');
                                    }

                                    this.setState(newState);
                                }).catch(e => {
                                    console.log(e);
                                    Alert.error('Error');
                                    this.setState(newState);
                                });
                            } else {
                                User.create(data).then(result => {
                                    if (result.status) {
                                        newState.originalFormValues = clone(this.state.formValues);

                                        Alert.info('Usuario creado');
                                        this.props.history.push(CallCenterUserForm.INDEX_ROUTE);
                                    } else {
                                        Alert.error('Error. No se pudo crear el usuario');
                                    }

                                    this.setState(newState);
                                }).catch(e => {
                                    console.log(e);
                                    Alert.error('Error');
                                    this.setState(newState);
                                });
                            }
                        } else {
                            this.setState(newState);
                        }
                    }
                });
            });
        }
    };

    renderSubmitButton = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <div>
                <Button className="btn-derive" onClick={this.onCancel}>CANCELAR</Button>
                <Button className="btn-derive" onClick={this.onSave}>{this.isEditMode() ? 'GUARDAR' : 'CREAR'}</Button>
            </div>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <Form>
                <div className="row flex">
                    <div className="col-sm-10 col-2 pad0">
                        <div className="row flex">
                            <div className="col-sm-6">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="username">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Input id="username"
                                               name="username"
                                               onChange={this.onChangeUsername}
                                               placeholder="Usuario *"
                                               type="text"
                                               value={this.state.formValues.username}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="email">
                                            <img alt="" src="/img/svg/icon_email.svg" width="20"/>
                                        </Label>
                                        <Input id="email"
                                               name="email"
                                               onChange={this.onChangeEmail}
                                               placeholder="Correo electrónico *"
                                               type="email"
                                               value={this.state.formValues.email}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="password">
                                            <img alt="" src="/img/svg/icon_padlock.svg" width="20"/>
                                        </Label>
                                        <Input id="password"
                                               name="password"
                                               onChange={this.onChangePassword}
                                               placeholder={'Contraseña' + (this.isEditMode() ? '' : ' *')}
                                               type="password"
                                               value={this.state.formValues.password}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="firstName">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Input id="firstName"
                                               name="firstName"
                                               onChange={this.onChangeFirstName}
                                               placeholder="Nombres *"
                                               type="text"
                                               value={this.state.formValues.firstName}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-6">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="lastName">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Input id="lastName"
                                               name="lastName"
                                               onChange={this.onChangeLastName}
                                               placeholder="Apellidos *"
                                               type="text"
                                               value={this.state.formValues.lastName}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="idDocNumber">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Input id="idDocNumber"
                                               name="idDocNumber"
                                               onChange={this.onChangeIdDocNumber}
                                               placeholder="Número de DNI *"
                                               type="text"
                                               value={this.state.formValues.idDocNumber}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="campaign">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Input id="campaign"
                                               name="campaign"
                                               onChange={this.onChangeCampaign}
                                               placeholder="Campaña"
                                               type="text"
                                               value={this.state.formValues.campaign}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="enabled">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Input id="enabled"
                                               name="enabled"
                                               onChange={this.onChangeEnabled}
                                               type="select"
                                               value={this.state.formValues.enabled}
                                        >
                                            <option value="">Selecciona...</option>
                                            <option value="true">Habilitado</option>
                                            <option value="false">Deshabilitado</option>
                                        </Input>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 col-2 pad0">
                        <div className="flex flex-btns">
                            <div>
                                {this.renderSubmitButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default withRouter(CallCenterUserForm);
