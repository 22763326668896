import {parallel, series} from 'async';
import {Wave} from 'better-react-spinkit';
import {clone, isArray, isEmpty, isNil, isUndefined} from 'lodash';
import React from 'react';
import {withRouter} from 'react-router-dom';
import Select from 'react-select';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import validate from 'validate.js';
import Alert from '../../../lib/Alert';
import Constants from '../../../lib/Constants';
import Brand from '../../../models/Brand';
import Dealership from '../../../models/Dealership';
import Store from '../../../models/Store';
import User from '../../../models/User';

class SalesUserForm extends React.Component {
    static INDEX_ROUTE = '/users';
    static defaultBrands = [
        {
            value: '',
            label: 'Selecciona...',
            disabled: true
        }
    ];
    static defaultDealerships = [
        {
            id: '',
            name: 'Concesionario *'
        }
    ];
    static defaultStores = [
        {
            id: '',
            name: 'Tienda *'
        }
    ];

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            model: {},
            brands: SalesUserForm.defaultBrands,
            selectedBrands: [],
            dealerships: SalesUserForm.defaultDealerships,
            stores: SalesUserForm.defaultStores,
            formValues: {
                username: '',
                password: '',
                email: '',
                firstName: '',
                lastName: '',
                dealershipId: '',
                storeId: '',
                position: 'Asesor de ventas',
                brandIds: [],
                enabled: '',
                points: 0
            },
            originalFormValues: [],
            processing: false
        };
    }

    componentDidMount() {
        let newState = {
            loading: false,
            model: {},
            brands: [],
            selectedBrands: [],
            dealerships: [],
            stores: SalesUserForm.defaultStores,
            formValues: {},
            originalFormValues: {}
        };
        let tasks = {
            brands: callback => {
                Brand.find().then(result => {
                    if (result.status) {
                        newState.brands = result.data;

                        callback(null, true);
                    } else {
                        callback(null, false);
                    }
                }).catch(() => callback(null, false));
            },
            dealerships: callback => {
                const params = `filter[where][deleted]=false`;

                Dealership.find(params).then(result => {
                    if (result.status) {
                        newState.dealerships = SalesUserForm.defaultDealerships.concat(result.data);

                        callback(null, true);
                    } else {
                        callback(null, false);
                    }
                }).catch(() => callback(null, false));
            }
        };

        if (this.isEditMode()) {
            tasks.model = callback => {
                const params = `filter[include]=store`;

                User.findById(this.props.id + '/withpoints', params).then(result => {
                    if (result.status) {
                        const model = result.data;
                        newState.model = model;
                        newState.formValues = Object.assign(this.state.formValues, {
                            username: model.username,
                            email: model.email,
                            firstName: model.firstName,
                            lastName: model.lastName,
                            brandIds: model.brandIds,
                            dealershipId: '',
                            storeId: model.storeId,
                            enabled: model.enabled,
                            points: model.points
                        });

                        if (!isNil(model.store)) {
                            newState.formValues.dealershipId = model.store.dealershipId;
                            const storeParams = `filter[where][dealershipId]=${model.store.dealershipId}`;

                            Store.find(storeParams).then(storesResult => {
                                if (storesResult.status) {
                                    newState.stores = SalesUserForm.defaultStores.concat(storesResult.data);
                                }

                                callback(null, true);
                            }).catch(() => callback(null, true));
                        } else {
                            callback(null, true);
                        }
                    } else {
                        callback(null, false);
                    }
                }).catch(() => callback(null, false));
            };
        }

        parallel(tasks, (err, result) => {
            if (this.isEditMode()) {
                if (result.model) {
                    let selectedBrands = [];

                    newState.formValues.brandIds.forEach(brandId => {
                        const filteredBrandIds = newState.brands.filter(x => x.id === brandId);

                        if (!isEmpty(filteredBrandIds)) {
                            const brand = filteredBrandIds[0];

                            selectedBrands.push({value: brand.id, label: brand.name});
                        }
                    });

                    newState.selectedBrands = selectedBrands;
                } else {
                    Alert.error('Usuario inválido');
                    this.props.history.push(SalesUserForm.INDEX_ROUTE);
                }
            }

            newState.originalFormValues = clone(newState.formValues);

            this.setState(newState);
        });
    }

    getBrandOptions = () => SalesUserForm.defaultBrands.concat(this.state.brands.map(x => {
        return {value: x.id, label: x.name};
    }));

    isEditMode = () => this.props.mode === Constants.Form.MODE_EDIT;

    onCancel = () => this.props.history.push(SalesUserForm.INDEX_ROUTE);

    onChangeUsername = e => this.setState({
        formValues: Object.assign(this.state.formValues, {username: e.target.value})
    });

    onChangeEmail = e => this.setState({
        formValues: Object.assign(this.state.formValues, {email: e.target.value})
    });

    onChangePassword = e => this.setState({
        formValues: Object.assign(this.state.formValues, {password: e.target.value})
    });

    onChangeFirstName = e => this.setState({
        formValues: Object.assign(this.state.formValues, {firstName: e.target.value})
    });

    onChangeLastName = e => this.setState({
        formValues: Object.assign(this.state.formValues, {lastName: e.target.value})
    });

    onChangeBrandIds = options => {
        let newState = {
            selectedBrands: options
        };
        const brandIds = options.map(x => x.value).filter(x => !isUndefined(x));

        if (!isEmpty(brandIds)) {
            newState.formValues = Object.assign(this.state.formValues, {brandIds: brandIds});
        }

        this.setState(newState);
    };

    onChangeDealershipId = e => {
        const dealershipId = e.target.value;
        let newState = {
            processing: true,
            formValues: Object.assign(this.state.formValues, {dealershipId: dealershipId, storeId: ''})
        };

        this.setState(newState, () => {
            const params = `filter[where][dealershipId]=${dealershipId}`;
            newState = {
                processing: false,
                stores: []
            };

            Store.find(params).then(result => {
                if (result.status) {
                    newState.stores = SalesUserForm.defaultStores.concat(result.data);
                }

                this.setState(newState);
            }).catch(() => this.setState(newState));
        });
    };

    onChangeStoreId = e => this.setState({formValues: Object.assign(this.state.formValues, {storeId: e.target.value})});

    onChangeEnabled = e => this.setState({
        formValues: Object.assign(this.state.formValues, {enabled: e.target.value === 'true'})
    });

    onChangePoints = e => {
        this.setState({
        formValues: Object.assign(this.state.formValues, {points: (e.target.value)?Math.trunc(e.target.value):0})
    })}

    onSave = () => {
        const constraints = {
            username: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el usuario'
                }
            },
            email: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa el correo electrónico'
                },
                email: {
                    message: 'El correo electrónico es inválido'
                }
            },
            firstName: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa los nombres'
                }
            },
            lastName: {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa los apellidos'
                }
            },
            brandIds: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona una o varias marcas'
                }
            },
            dealershipId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el concesionario'
                }
            },
            storeId: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona la tienda'
                }
            },
            enabled: {
                presence: {
                    allowEmpty: false,
                    message: 'Selecciona el estado'
                }
            },
            points: {
                presence: {
                    allowEmpty: false,
                    message: 'Coloque los puntos'
                }
            }
        };

        if (!this.isEditMode()) {
            constraints.password = {
                presence: {
                    allowEmpty: false,
                    message: 'Ingresa la contraseña'
                },
                length: {
                    minimum: 8,
                    message: 'La contraseña debe tener 8 caracteres como mínimo'
                }
            };
        }

        const result = validate(this.state.formValues, constraints, {format: 'flat', fullMessages: false});

        if (isArray(result)) {
            Alert.error(result.join('<br>'));
        } else {
            this.setState({processing: true}, () => {
                let initialData = {
                    username: '',
                    password: '',
                    email: '',
                    role: this.props.role,
                    firstName: '',
                    lastName: '',
                    photoFilename: '',
                    rating: 0,
                    indicators: [],
                    points: 0,
                    position: 'Asesor de ventas',
                    storeId: null,
                    brandIds: [],
                    enabled: true ,
                    

                };

                if (!this.isEditMode()) {
                    initialData.deleted = false;
                }

                let data = Object.assign(initialData, clone(this.state.formValues));

                delete data.dealershipId;

                let newState = {
                    processing: false
                };
                const errorMessages = {
                    username: {
                        error: 'Lo sentimos, ocurrió un error validando el usuario',
                        duplicated: 'El usuario ya está en uso, por favor ingrese otro'
                    },
                    email: {
                        error: 'Lo sentimos, ocurrió un error validando el correo electrónico',
                        duplicated: 'El correo electrónico ya está en uso, por favor ingrese otro'
                    }
                };
                let tasks = {
                    username: callback => {
                        const params = `filter[where][username]=${this.state.formValues.username}&filter[where][enabled]=true&filter[where][deleted]=false`;

                        User.find(params).then(result => {
                            if (result.status) {
                                if (!isEmpty(result.data)) {
                                    Alert.error(errorMessages.username.duplicated);
                                }

                                callback(null, {isOk: isEmpty(result.data)});
                            } else {
                                Alert.error(errorMessages.username.error);
                                callback(null, {isOk: false});
                            }
                        }).catch(e => {
                            console.log(e);
                            Alert.error(errorMessages.username.error);
                            callback(e);
                        });
                    },
                    email: callback => {
                        const params = `filter[where][email]=${this.state.formValues.email}&filter[where][enabled]=true&filter[where][deleted]=false`;

                        User.find(params).then(result => {
                            if (result.status) {
                                if (!isEmpty(result.data)) {
                                    Alert.error(errorMessages.email.duplicated);
                                }

                                callback(null, {isOk: isEmpty(result.data)});
                            } else {
                                Alert.error(errorMessages.email.error);
                                callback(null, {isOk: false});
                            }
                        }).catch(e => {
                            console.log(e);
                            Alert.error(errorMessages.email.error);
                            callback(e);
                        });
                    }
                };

                if (this.isEditMode()) {
                    if (this.state.formValues.username === this.state.originalFormValues.username) {
                        delete data.username;
                        delete tasks.username;
                    }

                    if (this.state.formValues.email === this.state.originalFormValues.email) {
                        delete data.email;
                        delete tasks.email;
                    }
                }

                if (isEmpty(tasks)) {
                    tasks.dummy = callback => callback(null, true);
                }

                series(tasks, (err, result) => {
                    if (err) {
                        console.log(err);
                        Alert.error('Error');
                    } else {
                        let values = [];

                        if (!isNil(result.username)) {
                            values.push(result.username.isOk);
                        }

                        if (!isNil(result.email)) {
                            values.push(result.email.isOk);
                        }

                        const proceed = isEmpty(values) || values.every(x => x === true);

                        if (proceed) {
                            // Process user
                            if (this.isEditMode()) {
                                if (!data.password) {
                                    delete data.password;
                                }

                                User.update(this.props.id, data).then(result => {
                                    if (result.status) {
                                        newState.originalFormValues = clone(this.state.formValues);

                                        Alert.info('Usuario editado');
                                    } else {
                                        Alert.error('Error. No se pudo editar el usuario');
                                    }

                                    this.setState(newState);
                                }).catch(e => {
                                    console.log(e);
                                    Alert.error('Error');
                                    this.setState(newState);
                                });
                            } else {
                                User.create(data).then(result => {
                                    if (result.status) {
                                        newState.originalFormValues = clone(this.state.formValues);

                                        Alert.info('Usuario creado');
                                        this.props.history.push(SalesUserForm.INDEX_ROUTE);
                                    } else {
                                        Alert.error('Error. No se pudo crear el usuario');
                                    }

                                    this.setState(newState);
                                }).catch(e => {
                                    console.log(e);
                                    Alert.error('Error');
                                    this.setState(newState);
                                });
                            }
                        } else {
                            this.setState(newState);
                        }
                    }
                });
            });
        }
    };

    renderSubmitButton = () => {
        if (this.state.processing) {
            return (
                <Wave color="#D81626" size={25}/>
            );
        }

        return (
            <div>
                <Button className="btn-derive" onClick={this.onCancel}>CANCELAR</Button>
                <Button className="btn-derive" onClick={this.onSave}>{this.isEditMode() ? 'GUARDAR' : 'CREAR'}</Button>
            </div>
        );
    };

    render() {
        if (this.state.loading) {
            return (
                <Wave color="#D81626" size={50}/>
            );
        }

        return (
            <Form>
                <div className="row flex">
                    <div className="col-sm-10 col-2 pad0">
                        <div className="row flex">
                            <div className="col-sm-6">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="username">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Input id="username"
                                               name="username"
                                               onChange={this.onChangeUsername}
                                               placeholder="Usuario *"
                                               type="text"
                                               value={this.state.formValues.username}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="email">
                                            <img alt="" src="/img/svg/icon_email.svg" width="20"/>
                                        </Label>
                                        <Input id="email"
                                               name="email"
                                               onChange={this.onChangeEmail}
                                               placeholder="Correo electrónico *"
                                               type="email"
                                               value={this.state.formValues.email}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="password">
                                            <img alt="" src="/img/svg/icon_padlock.svg" width="20"/>
                                        </Label>
                                        <Input id="password"
                                               name="password"
                                               onChange={this.onChangePassword}
                                               placeholder={'Contraseña' + (this.isEditMode() ? '' : ' *')}
                                               type="password"
                                               value={this.state.formValues.password}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="firstName">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Input id="firstName"
                                               name="firstName"
                                               onChange={this.onChangeFirstName}
                                               placeholder="Nombres *"
                                               type="text"
                                               value={this.state.formValues.firstName}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="lastName">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Input id="lastName"
                                               name="lastName"
                                               onChange={this.onChangeLastName}
                                               placeholder="Apellidos *"
                                               type="text"
                                               value={this.state.formValues.lastName}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-6">
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="dealershipId">
                                            <img alt="" src="/img/svg/icon_tienda.svg" width="20"/>
                                        </Label>
                                        <Input id="dealershipId"
                                               name="dealershipId"
                                               onChange={this.onChangeDealershipId}
                                               type="select"
                                               value={this.state.formValues.dealershipId}
                                        >
                                            {this.state.dealerships.map(dealership =>
                                                <option key={`dealership-${dealership.id}`}
                                                        value={dealership.id}>{dealership.name}
                                                </option>
                                            )}
                                        </Input>
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="storeId">
                                            <img alt="" src="/img/svg/icon_tienda.svg" width="20"/>
                                        </Label>
                                        <Input id="storeId"
                                               name="storeId"
                                               onChange={this.onChangeStoreId}
                                               type="select"
                                               value={this.state.formValues.storeId}
                                        >
                                            {this.state.stores.map(store =>
                                                <option key={`store-${store.id}`}
                                                        value={store.id}>{store.name}
                                                </option>
                                            )}
                                        </Input>
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="brandIds">
                                            <img alt="" src="/img/svg/icon_tienda.svg" width="20"/>
                                        </Label>
                                        <Select id="brandIds"
                                                name="brandIds"
                                                isMulti={true}
                                                isOptionDisabled={option => option.disabled}
                                                onChange={this.onChangeBrandIds}
                                                options={this.getBrandOptions()}
                                                placeholder="Marcas *"
                                                value={this.state.selectedBrands}
                                        />
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="enabled">
                                            <img alt="" src="/img/svg/icon_nombre.svg" width="20"/>
                                        </Label>
                                        <Input id="enabled"
                                               name="enabled"
                                               onChange={this.onChangeEnabled}
                                               type="select"
                                               value={this.state.formValues.enabled}
                                        >
                                            <option value="">Seleccionar...</option>
                                            <option value="true">Habilitado</option>
                                            <option value="false">Deshabilitado</option>
                                        </Input>
                                    </div>
                                </FormGroup>
                                <FormGroup className="form-group">
                                    <div className="icon-addon addon-lg">
                                        <Label className="glyphicon" for="points">
                                            <img alt="" src="/img/svg/icon_points.svg" width="20"/>
                                        </Label>
                                        <Input id="points"
                                               name="points"
                                               placeholder = "0"
                                               onChange={this.onChangePoints}
                                               type="number"
                                               value={this.state.formValues.points}
                                        >
                                            
                                        </Input>
                                    </div>
                                </FormGroup>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 col-2 pad0">
                        <div className="flex flex-btns">
                            <div>
                                {this.renderSubmitButton()}
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default withRouter(SalesUserForm);
