import {in_array as inArray} from 'locutus/php/array';
import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {Form, FormGroup, Label} from 'reactstrap';
import CallCenterUserForm from '../../../components/forms/users/CallCenterUserForm';
import ManagementUserForm from '../../../components/forms/users/ManagementUserForm';
import SalesUserForm from '../../../components/forms/users/SalesUserForm';
import Constants from '../../../lib/Constants';
import Utils from '../../../lib/Utils';

class Create extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            role: ''
        };
    }

    // Set all roles that will be listed in dropdown
    getRoles = () => {
        return [
            Constants.Role.ADMIN,
            Constants.Role.COORDINATOR,
            Constants.Role.MAIN_MANAGER,
            Constants.Role.BUSINESS_MANAGER,
            Constants.Role.MARKETING_MANAGER,
            Constants.Role.BUSINESS_DELEGATE,
            Constants.Role.DEALERSHIP_MANAGER,
            Constants.Role.SALES_MANAGER,
            Constants.Role.SALES,
            Constants.Role.CALL_CENTER_SUPERVISOR,
            Constants.Role.CALL_CENTER,
            Constants.Role.BACKOFFICE,
        ];
    };
    // Set all roles that will be listed in dropdown

    // Get value according to the role
    getRoleOptions = () => this.getRoles().map(role => {
        return {value: role, label: Utils.getUserRoleName(role)};
    });
    // Get value according to the role

    onChangeRole = e => this.setState({role: e.value});

    renderForm = () => {
        const managementRoles = [
            Constants.Role.ADMIN,
            Constants.Role.COORDINATOR,
            Constants.Role.MAIN_MANAGER,
            Constants.Role.BUSINESS_MANAGER,
            Constants.Role.MARKETING_MANAGER,
            Constants.Role.BUSINESS_DELEGATE,
            Constants.Role.DEALERSHIP_MANAGER,
            Constants.Role.SALES_MANAGER,
            Constants.Role.CALL_CENTER_SUPERVISOR,
            Constants.Role.BACKOFFICE
        ];

        if (inArray(this.state.role, managementRoles)) {
            return <ManagementUserForm mode={Constants.Form.MODE_CREATE} role={this.state.role}/>;
        } else if (this.state.role === Constants.Role.SALES) {
            return <SalesUserForm mode={Constants.Form.MODE_CREATE} role={this.state.role}/>;
        } else if (this.state.role === Constants.Role.CALL_CENTER) {
            return <CallCenterUserForm mode={Constants.Form.MODE_CREATE} role={this.state.role}/>;
        }

        return null;
    };

    render() {
        return (
            <div className="container content" style={{maxWidth : '100%'}}>
                <div className="row flex mrgBtm30">
                    <div className="col-sm-12">
                        <div className="flex-between">
                            <div>
                                <h2 className="dates-client">CREAR USUARIO</h2>
                                <div className="subline"/>
                            </div>
                        </div>
                    </div>
                </div>
                <Form>
                    <div className="row flex">
                        <div className="col-sm-10 col-2 pad0">
                            <div className="row flex">
                                <div className="col-sm-12">
                                    <FormGroup className="form-group">
                                        <Label className="glyphicon pr-2 mb-0" for="role">Cargo</Label>
                                        <Dropdown id="role"
                                                  name="role"
                                                  className={"form-control input-select-report"}
                                                  onChange={this.onChangeRole}
                                                  options={this.getRoleOptions()}
                                                  placeholder="Selecciona..."
                                                  value={this.state.role}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                {this.renderForm()}
            </div>
        );
    }
}

export default Create;
