import _IndexTable  from './_IndexTable';
import Dealership from '../../../../models/Dealership'

export default class DealershipTable extends _IndexTable {
  
  constructor(props)
  {
    
    super(props);
    this.submitChange = this.submitChange.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);

  }

  componentDidMount() 
  {
    this.setState({app:{
      name_table:"Consesionarias",
      name_column:"Nombre concesionaria"
    }})
  }

  /**
   * [OVERRIDE] retorna el nombre de la columna
   * @returns {string}
   */
   getNameColumn()
   {
     return "Nombre concesionaria";
   }

  getData()
  {
    const params = 'filter[where][enabled]=true&filter[where][deleted]=false'
    Dealership.find(params).then(x => {this.setState({rows: x.data})})
  }

  async submitChange(id,coti_name)
  {
    var temp = await  Dealership.changeCotiName(id,coti_name);
    var data = await Dealership.find();
    document.body.style.cursor = 'default';
    await this.setState({rows: data.data,modal:{show : false}})
  }
}