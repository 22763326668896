import React from 'react';

const Index = () => (
    <div className="container content" style={{maxWidth : '100%'}}>
        <div className="row flex mrgBtm30">
            <div className="col-sm-12">
                <div>
                    <div>
                        <h2 className="dates-client">BIENVENID@</h2>
                        <div className="subline"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Index;
